import { useState, useMemo, useEffect, useCallback } from "react";
import {
  Container,
  Typography,
  Grid,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@mui/material";
import { PlayCircle, StopCircle } from "@mui/icons-material";
import useWebSocket from "react-use-websocket";
import { toast } from "react-toastify";
import Select from "react-select";
import { Helmet } from "react-helmet";

import {
  useFetchAllRedPandaTopicsQuery,
  useFetchEntitiesSummaryQuery,
  entitiesSummaryQueryKeys,
  textSummaryQueryKeys,
  useFetchTextSummaryQuery,
} from "../queries/speechToText";

import ExportFileButton from "../components/exportFileButton";
import MessageList from "../components/messageList";
import EntitiesList from "../components/entitiesList";
import SpeechSummarization from "../components/speechSummarization";

const messageCutOffIdx = 5;

const brokerWebSocketURL = process.env.REACT_APP_BROKER_WEBSOCKET_URL;

export default function SpeechToText() {
  const {
    data: brokerTopics,
    isLoading: isLoadingBrokerTopics,
    error,
  } = useFetchAllRedPandaTopicsQuery();

  const [selectedBrokerTopic, setSelectedBrokerTopic] = useState(null);
  const [webSocketConnected, setWebSocketConnected] = useState(false);
  const [messages, setMessages] = useState([]);
  const [entities, setEntities] = useState([]);
  const [currentMessageIdx, setCurrentMesageIdx] = useState(0);
  const [summarizedMessages, setSummarizedMessages] = useState("");
  const [combinedMessages, setCombinedMessages] = useState("");

  const { data: entitiesData } = useFetchEntitiesSummaryQuery(
    summarizedMessages,
    {
      enabled: !!(
        webSocketConnected &&
        messages.length &&
        messages.length % messageCutOffIdx === 0
      ),
      queryKey: entitiesSummaryQueryKeys.single(summarizedMessages),
    }
  );

  const {
    isLoading: isLoadingTextSummary,
    isRefetching: isRefetchingTextSummary,
    data: summaryData,
    refetch,
  } = useFetchTextSummaryQuery(combinedMessages, {
    queryKey: textSummaryQueryKeys.single(combinedMessages),
    enabled: false,
    refetchOnWindowFocus: false,
  });

  const formatEntities = useCallback(() => {
    const allEntities = [...entities];
    const newEntities = entitiesData?.entities.map(([value, type]) => ({
      value,
      type,
      mentions: 1,
    }));

    newEntities?.forEach((newEntity) => {
      const existingIdx = allEntities.findIndex(
        (existingEntity) =>
          existingEntity.value.toLowerCase() ===
            newEntity.value.toLowerCase() &&
          existingEntity.type.toLowerCase() === newEntity.type.toLowerCase()
      );
      if (existingIdx !== -1) {
        //@ts-ignore
        allEntities[existingIdx].mentions += 1;
      } else {
        allEntities.push(newEntity);
      }
    });

    return allEntities;
  }, [entitiesData, entities]);

  const combineMessages = useCallback(() => {
    const slicedMessages = messages.slice(
      currentMessageIdx,
      currentMessageIdx + messageCutOffIdx
    );
    setCurrentMesageIdx(currentMessageIdx + messageCutOffIdx);

    const combinedMessages = slicedMessages.map((msg) => msg.text).join("");
    setSummarizedMessages(combinedMessages);
  }, [messages.length]);

  console.log("MEssage", messages);

  useWebSocket(
    `${brokerWebSocketURL}/broker/stt/${selectedBrokerTopic?.value}`,
    {
      onClose: () => {
        toast.success("WebSocket connection has closed");
      },
      onError: (err) => {
        console.log("On Error", err);
        toast.error("An error occurred with the WebSocket connection");
        setWebSocketConnected(false);
      },
      onOpen: () => {
        toast.success("WebSocket connection has started");
      },
      onMessage: (event) => {
        if (event.data) {
          const parsedMessage = JSON.parse(event.data);
          if (!messages.some((msg) => msg.uid === parsedMessage.uid)) {
            setMessages([...messages, parsedMessage]);
          }
        }
      },
    },
    webSocketConnected
  );

  const brokerTopicOptions = useMemo(
    () =>
      brokerTopics?.map((type) => ({
        value: type.toLowerCase(),
        label: type.toUpperCase(),
      })),
    [brokerTopics]
  );

  useEffect(() => {
    /* on mount set first option as default */
    if (brokerTopicOptions) {
      setSelectedBrokerTopic(brokerTopicOptions[0]);
    }
  }, [brokerTopicOptions]);

  useEffect(() => {
    if (entitiesData?.entities) {
      const allEntities = formatEntities();

      setEntities(allEntities);
    }
  }, [entitiesData]);

  useEffect(() => {
    if (messages.length && messages.length % messageCutOffIdx === 0) {
      combineMessages();
    }
  }, [messages, combineMessages]);

  const toggleWebSocketConnection = () =>
    setWebSocketConnected(!webSocketConnected);

  const handleRefetch = () => {
    const joinMessages = messages.map((msg) => msg.text).join("");
    setCombinedMessages(joinMessages);
    refetch();
  };

  return (
    <div>
      <Helmet>
        <title>Audio Biometric Exploitation</title>
      </Helmet>
      <Container sx={{ mt: 3, mb: 3 }}>
        <Typography
          component="h4"
          mb={1}
          className="speechToTextPageTitle"
          textAlign="center"
        >
          Audio Biometric Exploitation
        </Typography>
        {/* <Typography component="span">
          biometric summary placeholder text
        </Typography> */}
        <Grid container rowSpacing={3}>
          {isLoadingBrokerTopics ? (
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent={"center"}
              alignItems="center"
            >
              <CircularProgress />
            </Grid>
          ) : error ? (
            <Grid item xs={12} textAlign={"center"}>
              <Typography
                variant="error"
                color="#E25465"
                fontWeight={500}
                fontSize="1.1rem"
              >
                Failed to retrieve broker topics
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid item container alignItems="center" spacing={1.5}>
                <Grid item xs={9} md={6}>
                  <Tooltip
                    title="Make sure to stop the websocket connection before selecting a different topic"
                    placement="top"
                  >
                    <div>
                      <Select
                        isDisabled={
                          isLoadingBrokerTopics || !!error || webSocketConnected
                        }
                        placeholder="Select topic..."
                        onChange={setSelectedBrokerTopic}
                        value={selectedBrokerTopic}
                        options={brokerTopicOptions}
                        styles={{
                          option: (base, { isFocused }) => ({
                            ...base,
                            backgroundColor: isFocused ? "#00acd4" : undefined,
                            color: isFocused ? "#FFF" : undefined,
                          }),
                        }}
                      />
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item xs={3} md={4}>
                  <Tooltip
                    placement="top"
                    title={
                      webSocketConnected ? "Stop WebSocket" : "Start WebSocket"
                    }
                  >
                    <IconButton
                      disabled={
                        isLoadingBrokerTopics ||
                        !!error ||
                        isRefetchingTextSummary
                      }
                      onClick={toggleWebSocketConnection}
                    >
                      {webSocketConnected ? (
                        <StopCircle color="error" fontSize="large" />
                      ) : (
                        <PlayCircle
                          color={
                            isLoadingBrokerTopics ||
                            !!error ||
                            isRefetchingTextSummary
                              ? "disabled"
                              : "success"
                          }
                          fontSize="large"
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  display="flex"
                  justifyContent={"flex-end"}
                >
                  <ExportFileButton
                    data={{
                      messages,
                      entities,
                      summary: summaryData?.full_summary,
                    }}
                    fileName="speech_text_and_entities.json"
                    fileType="text/json"
                    isDisabled={
                      webSocketConnected ||
                      isLoadingBrokerTopics ||
                      !!error ||
                      !brokerTopics?.length ||
                      !messages.length ||
                      isRefetchingTextSummary
                    }
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Typography textAlign="center" variant="h6" color="GrayText">
                    Speech
                  </Typography>
                  <MessageList
                    messages={messages}
                    webSocketConnected={webSocketConnected}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography textAlign="center" variant="h6" color="GrayText">
                    Entities
                  </Typography>
                  <EntitiesList
                    webSocketConnected={webSocketConnected}
                    entities={entities}
                  />
                </Grid>
                <SpeechSummarization
                  isLoading={isLoadingTextSummary || isRefetchingTextSummary}
                  isDisabled={
                    isLoadingTextSummary ||
                    webSocketConnected ||
                    isLoadingBrokerTopics ||
                    !!error ||
                    !brokerTopics?.length ||
                    !messages.length
                  }
                  fullSummary={summaryData?.full_summary}
                  onRefetch={handleRefetch}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
}
