import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Grid,
  // IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material/";

import { DOWNLOAD_ICON, UPLOAD_ICON } from "../assets/icons/IconList";
// import DeleteIcon from "@mui/icons-material/Delete";
import ProgressBar from "../components/progress";
// import { DeleteCard } from "./delete";

const FILE_TEXT_EXTRACTION_ENDPOINT =
  process.env.REACT_APP_FILE_TEXT_EXTRACTION_URL;
const SUMMARIZATION_RESPONSE_URL =
  process.env.REACT_APP_SPEECH_SUMMARIZATION_URL;

const NLP_CORE_BASE_URL = process.env.REACT_APP_NLP_CORE_BASE_URL;

const maxLength = 20;
function nameLengthValidator(file) {
  if (file.name.length > maxLength) {
    return {
      code: "name-too-large",
      message: `Name is larger than ${maxLength} characters`,
    };
  }
  return null;
}
export default function DragAndDrop(props) {
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [updateProgress, setUpdateProgress] = React.useState();
  const [hideButton, setHideButton] = React.useState(false);
  const [open, setOpen] = useState(false);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      multiple: false,
      disabled: hideButton,
      // validator: nameLengthValidator,
      accept: {
        "application/pdf": [".pdf", ".txt", ".doc", ".docx"],
      },
    });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <span key={file.path} style={{ color: "#006BB6" }}>
      &nbsp; {file.path} - {file.size} bytes
    </span>
  ));

  // const fileRejectionItems = fileRejections.map(({ file, errors }) => (
  //   <li key={file.path}>
  //     {file.path} - {file.size} bytes
  //     <ul>
  //       {errors.map((e) => (
  //         <li key={e.code}>{e.message}</li>
  //       ))}
  //     </ul>
  //   </li>
  // ));

  const handleDeleteConfirm = () => {
    // Perform delete operation
    setOpen(false);
  };

  const handleDeleteCancel = () => {
    setOpen(false);
  };

  const handleUploadFiles = async () => {
    props.transView(true);
    setShowSpinner(true);
    setHideButton(true);
    props.spinner(true);

    try {
      let formData = new FormData();
      formData.append("file", acceptedFiles[0]);

      const uploadResponse = await axios.post(
        FILE_TEXT_EXTRACTION_ENDPOINT,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );

      const summary = uploadResponse.data.content.content;

      props.meta(uploadResponse.data.content.metadata);
      setUpdateProgress(35);
      props.view(true);
      setShowSpinner(false);
      setTimeout(() => {
        setUpdateProgress(50);
      }, 3000);

      const summaryResponse = await axios.post(SUMMARIZATION_RESPONSE_URL, {
        input_text: summary,
      });
      setUpdateProgress(65);
      props.summary(summaryResponse.data.full_summary);

      const fullSummary = summaryResponse.data.full_summary;

      const entitiesResponse = await axios.post(`${NLP_CORE_BASE_URL}/ner`, {
        prompt: fullSummary,
      });

      props.nersData(entitiesResponse.data.entities);

      const htmlResponse = await axios.post(`${NLP_CORE_BASE_URL}/get_html`, {
        text: fullSummary,
        entity_data: entitiesResponse.data.entities.map((entity) => ({
          entity: entity[0],
          type: entity[1],
        })),
      });

      props.entitiesHTML(htmlResponse.data);
      setUpdateProgress(100);
    } catch (error) {
      toast.error("An error occurred, please try again...");
      // console.log(error);
      // You may add further error handling here based on your requirements
    } finally {
      props.transView(false);
      setShowSpinner(false);
      setHideButton(false);
      props.spinner(false);
    }
  };

  return (
    <Grid xs={12} container>
      <Grid
        xs={12}
        item
        {...getRootProps({ className: "dropzone" })}
        style={{
          margin: "0 auto",
          border: "dashed 2px #D0D0D0",
          borderRadius: "5px",
          padding: "25px",
          textAlign: "center",
          cursor: "pointer",
        }}
        className="css-selector"
      >
        <div className="spacer" />
        <Grid item xs={12}>
          <DOWNLOAD_ICON />
          <div className="spacer" />
          <div>
            <input {...getInputProps()} />
            <Typography
              sx={{ textDecoration: "underline" }}
              style={{ color: "#006bb6" }}
              variant="h6"
            >
              Click to upload
            </Typography>
            <Typography variant="h6">or drag and drop</Typography>
            <div className="spacer" />

            {/* <Typography
              sx={{ fontStyle: "italic" }}
              variant="subtitle2"
              style={{ color: "#999" }}
            >
              (Only files with name less than 20 characters will be accepted)
            </Typography> */}
          </div>
          <aside>
            <Typography style={{ color: "#00acd4" }}>
              {acceptedFileItems}
              {/* {acceptedFileItems.length > 0 && fileUploaded && (
                <IconButton onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              )} */}

              <Dialog open={open} onClose={handleDeleteCancel}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                  <Typography>
                    Are you sure you want to delete the file?
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDeleteCancel}>Cancel</Button>
                  <Button onClick={handleDeleteConfirm} color="error">
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Typography>
            <div className="spacer" />
            <div className="spacer" />
          </aside>
        </Grid>
      </Grid>

      <div className="spacer" />
      <Grid item xs={12}>
        {/* {acceptedFileItems.length < 1 ? "" : handleUploadFiles()} */}
        {acceptedFileItems.length != 1 ? (
          ""
        ) : (
          <span>
            {!hideButton && (
              <Button
                variant="contained"
                onClick={handleUploadFiles}
                style={{
                  margin: "0 auto",
                  display: "flex",
                  backgroundColor: "#bcd63e",
                  width: "40%",
                  padding: "10px",
                }}
              >
                UPLOAD
              </Button>
            )}
          </span>
        )}
        <div className="spacer" />
      </Grid>

      {showSpinner && (
        <Grid
          item
          xs={12}
          alignItems={"center"}
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <CircularProgress />
        </Grid>
      )}

      {updateProgress <= 100 && (
        <ProgressBar
          progressUpdate={updateProgress}
          fileName={acceptedFileItems}
        />
      )}

      {/* <div className="spacer" /> */}

      {/* <Grid item xs={12}>
        <DeleteCard fileName={acceptedFileItems}  dialogTriggerDisabled={hideButton}/>
      </Grid> */}
    </Grid>
  );
}
