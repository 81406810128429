import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";

const SpeechSummarization = ({
  isLoading,
  isDisabled,
  fullSummary,
  onRefetch,
}) => {
  return (
    <Grid item container mt={1.5}>
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        display="flex"
        flexDirection="row"
        mb={2}
      >
        <Grid item xs={3}>
          <Button onClick={onRefetch} variant="contained" disabled={isDisabled}>
            Summarize
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Typography textAlign="center" variant="h6" color="GrayText">
            Speech Summarization
          </Typography>
        </Grid>
        <Grid item xs={3} />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <LinearProgress
            variant={isLoading ? "indeterminate" : "determinate"}
            sx={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
            value={isLoading ? undefined : 0}
          />

          <Paper
            elevation={2}
            sx={{
              height: 250,
              overflow: "scroll",
              backgroundColor: "#f8f7f7",
              position: "relative",
              p: 4,
            }}
          >
            <Typography variant="body1">{fullSummary}</Typography>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SpeechSummarization;
