import { useState, useMemo } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  TextField,
  CircularProgress,
  MenuItem,
  IconButton,
} from "@mui/material/";
import { useDropzone } from "react-dropzone";
import {
  CloudUpload,
  CheckCircle,
  Error,
  Portrait,
  Replay,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postEmployee } from "../service/recognition";
import EmployeeCard from "./employeeCard";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  department: yup.string().required(),
  company: yup.string().required(),
  imageUpload: yup.mixed().required("Image is required"),
  jobTitle: yup.string().required("Job title is required"),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
});

const AddEmployeePanel = () => {
  const [registrationStatus, setRegistrationStatus] = useState(null);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles) => {
      setValue("imageUpload", acceptedFiles[0]);
      setRegistrationStatus(null);
    },
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { isSubmitting, errors, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      imageUpload: null,
      firstName: "",
      lastName: "",
      jobTitle: "",
      department: "",
      phoneNumber: "",
      company: "",
    },
  });

  const imgPreviewUrl = useMemo(() => {
    const imageUpload = getValues("imageUpload");
    return imageUpload ? URL.createObjectURL(imageUpload) : null;
  }, [getValues("imageUpload")]);

  const addEmployee = async (data) => {
    try {
      const employeeData = new FormData();
      employeeData.append("firstName", data.firstName);
      employeeData.append("lastName", data.lastName);
      employeeData.append("jobTitle", data.jobTitle);
      employeeData.append("department", data.department);
      employeeData.append("company", data.company);
      employeeData.append("phoneNumber", data.phoneNumber);
      if (data.imageUpload) employeeData.append("image", data.imageUpload);

      const response = await postEmployee(employeeData);

      setRegistrationStatus(response);
      if (response.Status === "Success") {
        toast.success(response.Message);
      } else if (response.Status === "Error") {
        toast.error(response.Message);
      }
    } catch (error) {
      toast.error("A server error occurred, please try again...");
      setRegistrationStatus({
        Status: error.response.data.Status,
        Message: error.response.data.Message,
      });
    }
  };

  const handleReset = () => {
    setRegistrationStatus(null);
    reset();
  };

  return (
    <Grid container display={"flex"} justifyContent={"center"}>
      {registrationStatus?.Status === "Success" && (
        <Grid item display="flex" justifyContent="center" mb={1.5}>
          <IconButton
            sx={{ boxShadow: "1px 1px 2px 1px gray" }}
            onClick={handleReset}
          >
            <Replay style={{ width: 24, height: 24 }} />
          </IconButton>
        </Grid>
      )}
      {isSubmitting ? (
        <Grid
          container
          item
          display={"flex"}
          alignItems="center"
          justifyContent={"center"}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container justifyContent={"center"}>
          {registrationStatus && (
            <Grid
              item
              alignItems="center"
              justifyContent={"center"}
              flexDirection={"column"}
              display="flex"
            >
              <Box display={"flex"} alignItems="center" mb={1.5}>
                <Typography
                  color={
                    registrationStatus?.Status === "Success" ? "green" : "red"
                  }
                  fontWeight={700}
                  textTransform={"uppercase"}
                >
                  {registrationStatus?.Message}
                </Typography>
                {registrationStatus.Status === "Success" ? (
                  <CheckCircle
                    style={{
                      width: 24,
                      height: 24,
                      color: "#008001",
                      marginLeft: 8,
                    }}
                  />
                ) : (
                  <Error
                    style={{
                      width: 24,
                      height: 24,
                      color: "red",
                      marginLeft: 8,
                    }}
                  />
                )}
              </Box>
              {registrationStatus?.Status === "Success" && (
                <EmployeeCard
                  showSimilarityPercentage={false}
                  userDetails={{
                    firstName: getValues("firstName"),
                    lastName: getValues("lastName"),
                    department: getValues("department"),
                    jobTitle: getValues("jobTitle"),
                    imageUrl: imgPreviewUrl,
                  }}
                />
              )}
            </Grid>
          )}
          {registrationStatus?.Status !== "Success" && (
            <>
              <Grid
                container
                display="flex"
                alignItems={"center"}
                columnGap={3.5}
              >
                <Grid
                  item
                  {...getRootProps({ className: "dropzone" })}
                  style={{
                    border: "dashed 2px #D0D0D0",
                    borderRadius: 4,
                    padding: 20,
                    textAlign: "center",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%",
                    maxWidth: "50%",
                    borderColor: errors?.imageUpload ? "red" : "#D0D0D0",
                  }}
                >
                  <CloudUpload
                    style={{ width: 80, height: 80, color: "#00acd4" }}
                  />
                  <input {...getInputProps()} {...register("imageUpload")} />
                  <Typography variant="h5" color="#64696f">
                    Click to upload image
                  </Typography>
                  <Typography variant="h5" color="#64696f">
                    or drag and drop
                  </Typography>
                  {acceptedFiles?.map((file) => (
                    <Typography color="#006BB6" key={file.name}>
                      {file?.path} - {file.size} bytes
                    </Typography>
                  ))}
                  {Boolean(errors.imageUpload) && (
                    <Typography color="red">
                      {errors.imageUpload.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4} height={300}>
                  {imgPreviewUrl ? (
                    <img
                      src={imgPreviewUrl}
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: 4,
                        boxShadow: "1px 1px 1px 1px gray",
                      }}
                    />
                  ) : (
                    <Box
                      style={{
                        width: 300,
                        height: "100%",
                        borderRadius: 4,
                        boxShadow: "1px 1px 1px 1px gray",
                      }}
                    >
                      <Portrait
                        style={{
                          width: "100%",
                          height: "100%",
                          color: "#00acd4",
                        }}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                container
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
              >
                <TextField
                  required
                  {...register("firstName")}
                  label="First Name"
                  error={Boolean(errors.firstName)}
                  sx={{ width: "100%", mt: 1.5 }}
                  helperText={
                    Boolean(errors.firstName) && (
                      <Typography>{errors.firstName.message}</Typography>
                    )
                  }
                />

                <TextField
                  label="Last Name"
                  {...register("lastName")}
                  error={Boolean(errors.lastName)}
                  required
                  sx={{ width: "100%", mt: 1.5 }}
                  helperText={
                    Boolean(errors.lastName) && (
                      <Typography>{errors.lastName.message}</Typography>
                    )
                  }
                />

                <TextField
                  label="Phone Number"
                  {...register("phoneNumber")}
                  error={Boolean(errors.phoneNumber)}
                  required
                  sx={{ width: "100%", mt: 1.5 }}
                  helperText={
                    Boolean(errors.phoneNumber) && (
                      <Typography>{errors.phoneNumber.message}</Typography>
                    )
                  }
                />

                <TextField
                  label="Job Title"
                  {...register("jobTitle")}
                  error={Boolean(errors.jobTitle)}
                  required
                  sx={{ width: "100%", mt: 1.5 }}
                  helperText={
                    Boolean(errors.jobTitle) && (
                      <Typography>{errors.jobTitle.message}</Typography>
                    )
                  }
                />

                <Controller
                  name="department"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      select
                      {...field}
                      label="Department"
                      sx={{ width: "100%", mt: 1.5 }}
                      required
                      error={Boolean(errors.department)}
                      helperText={
                        Boolean(errors.department) && (
                          <Typography>{errors.department.message}</Typography>
                        )
                      }
                    >
                      <MenuItem value="">Select Department</MenuItem>
                      <MenuItem value="artificial">Artificial</MenuItem>
                      <MenuItem value="cloud">Cloud</MenuItem>
                      <MenuItem value="security">Security</MenuItem>
                      <MenuItem value="logistics">Logistics</MenuItem>
                    </TextField>
                  )}
                />

                <Controller
                  name="company"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      select
                      {...field}
                      label="Company"
                      sx={{ width: "100%", mt: 1.5 }}
                      required
                      error={Boolean(errors.company)}
                      helperText={
                        Boolean(errors.company) && (
                          <Typography>{errors.company.message}</Typography>
                        )
                      }
                    >
                      <MenuItem value="">Select Company</MenuItem>
                      <MenuItem value="dataiku">Dataiku</MenuItem>
                      <MenuItem value="dataShapes">Data Shapes AI</MenuItem>
                      <MenuItem value="finch">Finch AI</MenuItem>
                      <MenuItem value="saic">SAIC</MenuItem>
                    </TextField>
                  )}
                />

                <Button
                  variant="contained"
                  sx={{ width: "50%", mt: 2, height: 50 }}
                  disabled={!isDirty}
                  onClick={handleSubmit(addEmployee)}
                >
                  Register
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default AddEmployeePanel;
