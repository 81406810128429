import { useEffect, useRef } from "react";
import { Paper, LinearProgress, Typography, Box, Grid } from "@mui/material";
import { VariableSizeList as List } from "react-window";

import AudioMeter from "./audioMeter";

const MessageList = ({ messages, webSocketConnected }) => {
  const listRef = useRef({});
  const rowHeights = useRef({});

  const setRowHeight = (index, size) => {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  };

  const getRowHeight = (index) => rowHeights.current[index] + 8 || 82;

  const scrollToBottom = () =>
    listRef.current.scrollToItem(messages.length - 1, "end");

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages]);

  const Row = ({ index, style }) => {
    const rowRef = useRef({});

    const msg = messages[index];

    useEffect(() => {
      if (rowRef.current) setRowHeight(index, rowRef.current.clientHeight);
    }, [rowRef]);

    return (
      <div style={{ ...style, padding: 24 }} key={msg.uid}>
        <Paper
          ref={rowRef}
          elevation={1}
          style={{
            padding: 20,
            width: "60%",
          }}
        >
          <Typography variant="body2" color={"textSecondary"}>
            {msg.text}
          </Typography>
          <Grid container alignItems={"center"} mt={1}>
            <AudioMeter decibelLevel={msg.avg_approx_decibels} />
          </Grid>
        </Paper>
      </div>
    );
  };

  return (
    <Box>
      <LinearProgress
        variant={webSocketConnected ? "indeterminate" : "determinate"}
        sx={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
        value={webSocketConnected ? undefined : 0}
      />

      <List
        ref={listRef}
        style={{
          backgroundColor: "#f8f7f7",
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
          borderRadius: 4,
          overflowY: "scroll",
          overflowX: "clip",
        }}
        height={500}
        itemCount={messages.length}
        itemSize={getRowHeight}
        width={"100%"}
      >
        {Row}
      </List>
    </Box>
  );
};

export default MessageList;
