import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import { ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AppWrapper from "./template/appTemplate";
import "./assets/sheets/main.scss";

import Search from "./search";
import Upload from "./upload";
import ComputerVision from "./computerVision";
import Capabilities from "./capabilities";
import Translator from "./translator";
import SpeechToText from "./content/speechToText";
import EdgeCvPage from "./edgeCv";
import EmployeeRecognitionPage from "./employeeRecognition";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import FacialRecognitionPage from "./facialRecognition";
import { APIProvider } from "@vis.gl/react-google-maps";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const queryClient = new QueryClient();

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <div className="appContainer">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Bounce}
        />
        <QueryClientProvider client={queryClient}>
          <AppWrapper>
            <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
              <BrowserRouter>
                <Routes>
                  <Route path="/" exact element={<Upload />} />
                  {/* <Route path="/search" exact element={<Search />} /> */}
                  <Route path="/upload" exact element={<Upload />} />
                  <Route
                    path="/computerVision"
                    exact
                    element={<ComputerVision />}
                  />
                  <Route
                    path="/capabilities"
                    exact
                    element={<Capabilities />}
                  />
                  <Route path="/translator" exact element={<Translator />} />
                  <Route
                    path="/speech-to-text"
                    exact
                    element={<SpeechToText />}
                  />
                  <Route path="/edge-cv" exact element={<EdgeCvPage />} />
                  <Route
                    path="/employee-recognition"
                    exact
                    element={<EmployeeRecognitionPage />}
                  />
                  <Route
                    path="/facial-recognition"
                    exact
                    element={<FacialRecognitionPage />}
                  />
                </Routes>
              </BrowserRouter>
            </APIProvider>
          </AppWrapper>
        </QueryClientProvider>
      </div>
    </StyledEngineProvider>
  );
}

export default App;
