import { useState } from "react";
import { Grid, Typography, Select, MenuItem, InputLabel } from "@mui/material/";
import CvImageUpload from "../components/cvImageUpload";

const selectOptions = [
  {
    label: "Instance Segmentation",
    value: "instance",
    disabled: false,
  },
  {
    label: "Semantic Segmentation",
    value: "semantic",
    disabled: true,
  },
];

export default function ComputerVisionContent() {
  const [segmentationType, setSegmentationType] = useState(
    selectOptions[0].value
  );

  const selectSegmentation = (event) => setSegmentationType(event.target.value);

  return (

      <Grid container xs={12}>
        <Grid xs={12} item>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "600",
              color: "#006bb6",
            }}
          >
            Instance Segmentation
          </Typography>
          <div className="spacer" />
          <div className="spacer" />
        </Grid>

        <Grid item xs={3}>
          <InputLabel id="demo-controlled-open-select-label">
            Please Choose Segmentation Type
          </InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            fullWidth
            value={segmentationType}
            label="Please Choose Segmentation Type"
            onChange={selectSegmentation}
          >
            {selectOptions.map((option) => (
              <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={9}>
          <CvImageUpload selectedSegmentation={segmentationType} />
        </Grid>
      </Grid>

  );
}
