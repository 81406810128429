import axios from "axios";

const FACIAL_RECOGNITION_BASE_URL =
  process.env.REACT_APP_FACIAL_RECOGNITION_URL;
const FACIAL_RECOGNITION_ANNOTATIONS_URL =
  process.env.REACT_APP_FACIAL_ANNOTATIONS_URL;

const ELASTIC_SEARCH_INSERT_URL =
  process.env.REACT_APP_ELASTIC_SEARCH_INSERT_BASE_URL;
export const postEmployee = async (employeeData) => {
  try {
    const response = await axios.post(
      `${FACIAL_RECOGNITION_BASE_URL}/employee-insert`,
      employeeData
    );
    return response.data;
  } catch (err) {
    console.log("An error occurred while attempting to POST employee");
    throw err;
  }
};

export const postVerifyEmployee = async (data) => {
  try {
    const response = await axios.post(
      `${FACIAL_RECOGNITION_BASE_URL}/employee-verification`,
      data
    );
    return response.data;
  } catch (err) {
    console.log("An error occurred while attempting to POST employee");
    throw err;
  }
};

export const postImageFaceAnnotationsJSON = async (data) => {
  try {
    const response = await axios.post(
      `${FACIAL_RECOGNITION_ANNOTATIONS_URL}/get-image-face-annotations-json`,
      data
    );
    return response.data;
  } catch (err) {
    console.log(
      "An error occurred while attempting to get the face annotations",
      err
    );
    throw err;
  }
};

export const postVerifyImageAgainstEmbeddings = async (data, coordinates) => {
  try {
    const params = new URLSearchParams();

    if (coordinates) {
      params.append("latitude", coordinates.lat);
      params.append("longitude", coordinates.lon);
    }
    const response = await axios.post(
      `${FACIAL_RECOGNITION_ANNOTATIONS_URL}/verify-image-against-embeddings`,
      data,
      {
        params,
      }
    );
    return response.data;
  } catch (err) {
    console.log(
      "An error occurred while attempting to get the face annotations",
      err
    );
    throw err;
  }
};

export const postElasticSearchInsert = async (jsonResponse) => {
  try {
    const response = await axios.post(
      `${ELASTIC_SEARCH_INSERT_URL}/elasticsearch/insert?index_name=facial-recognition`,
      {
        documents: [jsonResponse],
      }
    );
    return response.data;
  } catch (err) {
    console.log(
      "An error occurred while attempting to get the face annotations",
      err
    );
    throw err;
  }
};
