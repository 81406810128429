import React from "react";
import { Grid, Typography } from "@mui/material/";
import FacialRecognitionContent from "./content/facialRecognition";
import { Helmet } from "react-helmet";

export default function FacialRecognitionPage() {
  return (
    <Grid container xs={12} spacing={3} className="capabilitiesContainer">
      <Helmet>
        <title>Facial Recognition</title>
      </Helmet>
      <Grid item xs={12} className="marginCenter">
        <Typography
          style={{ color: "#006bb6", textAlign: "center" }}
          variant="h5"
          mb={2}
        >
          High Value Target Identification
        </Typography>
        <FacialRecognitionContent />
      </Grid>
    </Grid>
  );
}
