import React from "react";
import { Grid } from "@mui/material/";
import UploadContent from "./content/upload";
import { Helmet } from "react-helmet";

export default function Upload() {
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      spacing={3}
      className="searchContainer"
    >
      <Grid item xs={12}>
        <Helmet>
          <title>NLP Capabilities</title>
        </Helmet>
        <UploadContent />
      </Grid>
    </Grid>
  );
}
