import axios from "axios";

const CAPABILITIES_REST_BASE_URL =
  process.env.REACT_APP_CAPABILITIES_REST_BASE_URL;

export const uploadVideo = async (data) => {
  try {
    const response = await axios.post(
      `${CAPABILITIES_REST_BASE_URL}/upload-video`,
      data
    );
    return response.data;
  } catch (err) {
    console.log(
      "An error occurred while attempting to get the face annotations",
      err
    );
    throw err;
  }
};

export const deleteVideo = async (videoId) => {
  try {
    const response = await axios.delete(
      `${CAPABILITIES_REST_BASE_URL}/delete-video/${videoId}`
    );
    return response.data;
  } catch (err) {
    console.log(
      "An error occurred while attempting to get the face annotations",
      err
    );
    throw err;
  }
};
