import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { isMobile } from "react-device-detect";
import {
  Grid,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import Select from "react-select";
import Webcam from "react-webcam";
import { CloudUpload, Cameraswitch } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Map, Marker } from "@vis.gl/react-google-maps";

import FacialRecognitionIdentityDisplay from "../components/facialRecognitionIdentityDisplay";
import {
  postVerifyImageAgainstEmbeddings,
  postElasticSearchInsert,
} from "../service/recognition";

const inputOptions = [
  { value: "upload", label: "Upload an image" },
  { value: "snapshot", label: "Take a snapshot" },
];
export default function FacialRecognitionContent() {
  const [selectedInputMethod, setInputMethod] = useState(inputOptions[0]);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [webcamFacingMode, setWebcamFacingMode] = useState("user");
  const [location, setLocation] = useState(null);

  const theme = useTheme();

  const isSmallViewport = useMediaQuery(theme.breakpoints.down("sm"));

  const elasticSearchInsertMutation = useMutation({
    mutationFn: (jsonResponse) => {
      return postElasticSearchInsert(jsonResponse);
    },
    onError: (error) => {
      console.log("Error", error);
      toast.error("An error occurred, try again");
    },
  });

  const mutation = useMutation({
    mutationFn: (formData) => {
      return postVerifyImageAgainstEmbeddings(formData, location);
    },
    onSuccess: (successData) => {
      if (successData?.Results) {
        toast.success("Image approved");
        elasticSearchInsertMutation.mutate(successData);
      } else {
        toast.error("Image not approved");
      }
    },
    onError: (error) => {
      console.log("Error", error);
      toast.error("An error occurred, try again");
    },
  });

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation({
              lat: position.coords.latitude,
              lon: position.coords.longitude,
            });
          },
          (error) => {
            toast.error(error.message);
          }
        );
      } else {
        toast.error("Geolocation is not supported by this browser.");
      }
    };

    getLocation();
  }, []);
  const webcamRef = useRef(null);

  const captureWebcamPhoto = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();

    const base64Data = imageSrc.split(",")[1];

    const binaryString = atob(base64Data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([bytes], { type: "image/png" });

    const file = new File([blob], "webcam-photo.png", { type: "image/png" });

    const fileFormData = new FormData();
    fileFormData.append("file", file);

    mutation.mutate(fileFormData);
  }, [webcamRef]);

  const resetWebcamPhoto = () => mutation.reset();

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onFileDialogCancel: () => {
      setAcceptedFiles([]);
      mutation.reset();
    },
    onDropAccepted: (acceptedFiles) => {
      setAcceptedFiles([...acceptedFiles]);
      const fileFormData = new FormData();
      fileFormData.append("file", acceptedFiles[0]);

      mutation.mutate(fileFormData, location);
    },
  });

  const boundingBoxImgPreviewUrl = useMemo(
    () =>
      mutation?.data?.image
        ? `data:image/jpeg;base64,${mutation.data.image}`
        : null,
    [mutation?.data]
  );

  const handleCameraSwitch = useCallback(() => {
    const nextFacingMode = webcamFacingMode === "user" ? "environment" : "user";
    setWebcamFacingMode(nextFacingMode);
  }, [webcamFacingMode]);

  return (
    <Grid
      container
      flex
      justifyContent={"center"}
      flexDirection={isSmallViewport ? "column" : "row"}
      columnSpacing={1.5}
    >
      <Grid
        xs={5}
        item
        container
        flexDirection={"column"}
        rowSpacing={3}
        sx={{ width: isSmallViewport ? "100%" : "50%" }}
        maxWidth={700}
      >
        <Grid item>
          <Select
            isDisabled={mutation.isPending}
            options={inputOptions}
            isSearchable={false}
            onChange={(option) => {
              setInputMethod(option);
              setAcceptedFiles([]);
              mutation.reset();
            }}
            value={selectedInputMethod}
            styles={{
              control: (base, { isDisabled }) => ({
                ...base,
                backgroundColor: isDisabled ? "#6C757D" : undefined,
              }),
              option: (base, { isFocused }) => ({
                ...base,
                backgroundColor: isFocused ? "#0E284F" : undefined,
                color: isFocused ? "#FFF" : undefined,
              }),
            }}
          />
        </Grid>
        <Grid
          item
          container
          flexDirection={"row"}
          alignItems="center"
          display="flex"
        >
          {mutation.isPending ? (
            <Grid item display="flex" justifyContent="center" flex={1}>
              <CircularProgress />
            </Grid>
          ) : selectedInputMethod?.value === "upload" ? (
            <Grid
              item
              {...getRootProps({ className: "dropzone" })}
              style={{
                border: "dashed 2px #D0D0D0",
                borderRadius: 4,
                padding: 20,
                textAlign: "center",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                height: 400,

                width: "100%",
              }}
            >
              {boundingBoxImgPreviewUrl ? (
                <img
                  src={boundingBoxImgPreviewUrl}
                  style={{
                    height: "100%",
                    maxWidth: 400,
                    width: "100%",
                    objectFit: "cover",
                    maxHeight: 250,
                    borderRadius: 4,
                  }}
                />
              ) : (
                <CloudUpload
                  style={{ width: 80, height: 80, color: "#162227" }}
                />
              )}
              <input {...getInputProps()} />
              <Typography variant="h5" color="#64696f">
                Click to upload image
              </Typography>
              <Typography variant="h5" color="#64696f">
                or drag and drop
              </Typography>
              {acceptedFiles?.map((file) => (
                <Typography
                  color="#006BB6"
                  key={file.name}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "80%",
                  }}
                >
                  {file?.path} - {file.size} bytes
                </Typography>
              ))}
            </Grid>
          ) : (
            <Grid
              item
              maxHeight={400}
              width={"100%"}
              display="flex"
              flexDirection={"column"}
              position={"relative"}
            >
              {!boundingBoxImgPreviewUrl && isMobile && (
                <IconButton
                  style={{
                    position: "absolute",
                    top: 20,
                    right: 20,
                    backgroundColor: "#0E284F",
                    borderRadius: 4,
                    cursor: "pointer",
                    boxShadow: "1px 1px 0px 1px",
                    zIndex: 1,
                  }}
                  onClick={handleCameraSwitch}
                >
                  <Cameraswitch style={{ color: "#FFE9BE" }} />
                </IconButton>
              )}
              {boundingBoxImgPreviewUrl ? (
                <img
                  src={boundingBoxImgPreviewUrl}
                  style={{
                    height: "100%",
                    width: "100%",
                    maxHeight: 400,
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                  }}
                />
              ) : (
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  videoConstraints={{
                    maxWidth: 400,
                    aspectRatio: isMobile? 1 : 2,
                    facingMode: webcamFacingMode,
                  }}
                  style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
                />
              )}
              <Button
                onClick={
                  boundingBoxImgPreviewUrl
                    ? () => resetWebcamPhoto()
                    : () => captureWebcamPhoto()
                }
                variant="contained"
                style={{
                  backgroundColor: "#0E284F",
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
              >
                {boundingBoxImgPreviewUrl ? "Retake Photo" : "Capture Photo"}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      {!mutation.isPending && (
        <Grid
          item
          container
          xs={isSmallViewport ? 12 : 4}
          flex
          flexDirection={"column"}
          rowSpacing={1.5}
        >
          <Grid
            item
            xs={5}
            style={{ maxWidth: "100%", marginTop: isSmallViewport ? 20 : 0 }}
          >
            {mutation?.data?.Device_Location ? (
              <Map
                style={{
                  borderRadius: 4,
                  overflow: "hidden",
                  maxWidth: "100%",
                  height: isSmallViewport ? 200 : "100%",
                }}
                defaultCenter={{
                  lat: mutation.data.Device_Location.latitude,
                  lng: mutation.data.Device_Location.longitude,
                }}
                zoom={10}
                defaultZoom={5}
                gestureHandling={"greedy"}
                disableDefaultUI={true}
              >
                <Marker
                  position={{
                    lat: mutation.data.Device_Location.latitude,
                    lng: mutation.data.Device_Location.longitude,
                  }}
                />
              </Map>
            ) : (
              <Skeleton
                variant="rounded"
                height={isSmallViewport ? 200 : "100%"}
              />
            )}
          </Grid>
          <Grid
            item
            xs={7}
            style={{
              maxWidth: "100%",
            }}
          >
            <FacialRecognitionIdentityDisplay
              identityResults={mutation?.data?.Results}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
