import { useQuery } from "@tanstack/react-query";

import { getAlltfLiteTopics } from "../service/edgeCv";

export const tfLiteQueryKeys = {
  all: ["allTfLiteTopics"],
};

export const useFetchAllTfLiteTopics = (options) =>
  useQuery({
    queryKey: tfLiteQueryKeys.all,
    queryFn: getAlltfLiteTopics,
    ...options,
  });
