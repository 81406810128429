import axios from "axios";

export const tfLite_BASE_URL = process.env.REACT_APP_TFLITE_BASE_URL;

export const getAlltfLiteTopics = async () => {
  try {
    const response = await axios.get(`${tfLite_BASE_URL}/tflite_cv/topics`);
    return response.data;
  } catch (error) {
    console.error("Error fetching all topics", error);
    throw error;
  }
};
