import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material/";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { DOWNLOAD_ICON } from "../assets/icons/IconList";

const postTypeOptions = [
  { value: "text", label: "Text" },
  { value: "upload", label: "Upload File" },
];

const TRANSLATION_ENDPOINT = process.env.REACT_APP_TRANSLATION_URL;
const FILE_TEXT_EXTRACTION_ENDPOINT =
  process.env.REACT_APP_FILE_TEXT_EXTRACTION_URL;

export default function TranslatorContent() {
  const [dataFiles, setDataFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    disabled: loading,
    accept: {
      "application/pdf": [".pdf", ".txt", ".doc", ".docx"],
    },
  });

  const { register, handleSubmit, control, setValue, watch } = useForm({
    defaultValues: {
      selectInputMethod: "text",
      translatedText: "",
      uploadedFiles: [],
    },
  });

  const watchSelectInputMethod = watch("selectInputMethod");

  const postUploadedFileTextExtration = async (data) => {
    const { uploadedFiles } = data;
    try {
      let formData = new FormData();
      formData.append("file", uploadedFiles[0]);

      const response = await axios.post(
        FILE_TEXT_EXTRACTION_ENDPOINT,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data.content.content;
    } catch (e) {
      console.log("Failed to extract file text", e);
      throw e;
    }
  };

  // Service Call
  const postTranslatedText = async (data) => {
    setLoading(true);
    try {
      let text = data.translatedText;

      if (data.selectInputMethod === postTypeOptions[1].value) {
        text = await postUploadedFileTextExtration(data);
      }

      const response = await axios.post(TRANSLATION_ENDPOINT, {
        input_text: text,
      });

      setDataFiles(response.data);
      toast.success("Successfully translated text");
    } catch (error) {
      setDataFiles([]);
      toast.error("An error occurred, please try again...");

      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formattedTranslations = useMemo(() => dataFiles?.small_translations?.join(' '), [dataFiles])

  const onSubmitError = (err) => {
    setDataFiles([]);
    toast.error(Object.values(err)[0].message);
  };

  useEffect(() => {
    // Set uploaded file to the form input value
    const setUploadedFilesVal = () => setValue("uploadedFiles", acceptedFiles);

    setUploadedFilesVal();
  }, [acceptedFiles, setValue]);

  return (
    <div
      style={{ height: "100%", minHeight: "100vh" }}
      className="marginCenter"
    >
      <Grid container style={{ margin: "0 auto" }} spacing={3} xs={12}>
        <Grid xs={6} item>
          <InputLabel>Input Method</InputLabel>
          <Controller
            name="selectInputMethod"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                disabled={loading}
                placeholder="Select a method"
                label="Select a method"
                sx={{ width: "50%" }}
              >
                {postTypeOptions.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
      </Grid>
      <Grid container xs={12} spacing={3} style={{ margin: "0 auto" }}>
        <Grid xs={6} item>
          {watchSelectInputMethod === postTypeOptions[0].value ? (
            <TextField
              disabled={loading}
              id="standard-multiline-static"
              label="Enter Text"
              placeholder="Language Detected "
              multiline
              fullWidth
              variant="filled"
              name="translatedText"
              onChange={(e) => e.target.value}
              className="inputBox"
              {...register("translatedText", {
                required:
                  watchSelectInputMethod === postTypeOptions[0].value
                    ? "Text can't be empty"
                    : false,
              })}
              maxRows={10}
              style={{ height: 400, overflow: 'scroll'}}
              inputProps={{

                style: {
                  fontSize: 14,
                  height: 400,
                  width: "90%",
                  padding: "0 14px",
                  fontWeight: "bold",
                
                },
              }}
            />
          ) : (
            <Controller
              name="uploadedFiles"
              control={control}
              disabled={loading}
              rules={{
                required:
                  watchSelectInputMethod === postTypeOptions[1].value
                    ? "Upload at least 1 file"
                    : false,
              }}
              render={({ field: { onChange } }) => (
                <Grid
                  item
                  {...getRootProps({ className: "dropzone" })}
                  style={{
                    margin: "0 auto",
                    border: "dashed 2px #D0D0D0",
                    borderRadius: "5px",
                    padding: "25px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  className="css-selector"
                >
                  <div className="spacer" />
                  <Grid item xs={12}>
                    <DOWNLOAD_ICON />
                    <div className="spacer" />
                    <div>
                      <input {...getInputProps()} />
                      <Typography
                        sx={{ textDecoration: "underline" }}
                        style={{ color: "#006bb6" }}
                        variant="h6"
                      >
                        Click to upload
                      </Typography>
                      <Typography variant="h6">or drag and drop</Typography>
                      <div className="spacer" />
                      {/* <Typography
                        sx={{ fontStyle: "italic" }}
                        variant="subtitle2"
                        style={{ color: "#999" }}
                      >
                        (Only files with name less than 20 characters will be
                        accepted)
                      </Typography> */}

                      {acceptedFiles?.map((file) => (
                        <Typography color="#006BB6" key={file.name}>
                          {file.path} - {file.size} bytes
                        </Typography>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              )}
            />
          )}
          <div className="spacer" />

          <Button
            disabled={loading}
            onClick={handleSubmit(postTranslatedText, onSubmitError)}
            variant="contained"
            style={{ padding: "15px" }}
          >
            Translate
          </Button>
        </Grid>

        <Grid xs={6} item className="fadePanel">
          {loading ? (
            <div class="loadingContainer">
              <div className="spacer" />
              <Typography style={{ color: "#999" }}>Translating ...</Typography>
              <div class="loading">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          ) : (
            <Typography>
              <Typography className="fadePanel">
                <span>
                  <b>Detected Language: </b>
                </span>
                {dataFiles.detected_language}
              </Typography>
              <Typography className="fadePanel">
                <span>
                  <b>Language Translation: </b>
                </span>
                {dataFiles.small_translations === undefined
                  ? ""
                  : formattedTranslations}
              </Typography>
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
