import { Mic } from "@mui/icons-material";
const numBars = 10;

const barWidth = 100 / numBars;

const AudioMeter = ({ decibelLevel }) => {
  const getBarColor = () => {
    if (decibelLevel <= 60) {
      return "#2e7d32";
    } else if (decibelLevel >=61 && decibelLevel <= 70) {
      return "#d7d700bd";
    } else {
      return "#d32f2f";
    }
  };

  return (
    <div
      style={{ display: "flex", alignItems: "center", flexDirection: "row" }}
    >
      <Mic style={{ fill: getBarColor() }} />
      <div
        style={{
          display: "flex",
          width: 100,
          height: 15,
          backgroundColor: "white",
        }}
      >
        {Array.from({ length: numBars }, (_, index) => {
          const isActive = index * (100 / numBars) < decibelLevel;
          return (
            <div
              key={index}
              style={{
                width: `${barWidth}%`,
                height: "100%",
                backgroundColor: isActive ? getBarColor() : "#a0a0a0",
                marginRight: "2px",
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default AudioMeter;
