import React, { useEffect, useState, useMemo } from "react";
import { Card, Grid, CardActions, Skeleton } from "@mui/material/";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import ReactPaginate from "react-paginate";
import {
  RIGHT_ARROW_ICON,
  LEFT_ARROW_ICON,
  ENTITY_ICON,
} from "../assets/icons/IconList";

function NERCard({ nersData }) {
  const formattedData = useMemo(() => Object.entries(nersData), [nersData]);

  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    // Fetch items from another resources.

    const endOffset = itemOffset + 7;

    setCurrentItems(formattedData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(formattedData.length / 7));
  }, [itemOffset, formattedData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 4) % formattedData.length;

    setItemOffset(newOffset);
  };

  return (
    <Card
      sx={{
        boxShadow: "1px 3.5px 0px rgba(177, 177, 177, 0.4)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          align="left"
          style={{ color: "#1582d0" }}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <ENTITY_ICON />
          &nbsp; Named Entities
        </Typography>
        <Grid
          container
          justifyContent="center"
          style={{ marginTop: "15px", height: "315px" }}
        >
          {currentItems?.map(([value, valueType]) => (
            <Grid item xs={12} key={value}>
              <Box>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Chip
                    label={valueType[0]}
                    variant="filled"
                    sx={{ width: "100%" }}
                  >
                    {valueType[0]}
                  </Chip>
                </Stack>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
      <CardActions>
        <ReactPaginate
          breakLabel="..."
          nextLabel={<RIGHT_ARROW_ICON />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={<LEFT_ARROW_ICON />}
          renderOnZeroPageCount={null}
          marginPagesDisplayed={2}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </CardActions>
    </Card>
  );
}

function InactiveNERCard(props) {
  return (
    <div>
      <Typography
        sx={{ color: "#006bb6" }}
        gutterBottom
        variant="h5"
        component="div"
        align="center"
      >
        Named Entities
      </Typography>

      <Skeleton
        variant="rectangular"
        width={"100%"}
        height={325}
        style={{ borderRadius: "10px" }}
      >
        <Grid
          container
          xs={12}
          style={{ margin: "0 auto", textAlign: "center" }}
        >
          <Grid item xs={12} style={{ margin: "0 auto" }}>
            {props.spinner && (
              <Grid item xs={12}>
                <div class="loadingContainer">
                  <div class="loading">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <div className="spacer" />
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Skeleton>
    </div>
  );
}

export { NERCard, InactiveNERCard };
