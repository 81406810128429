import React, { useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material"
import {
  CHECK_MARK_ICON
} from "../assets/icons/IconList"

function LinearProgressWithLabel(props) {
  useEffect(() => {
    if (props.value === 100) {
      console.log("Switch View");
    }
  }, []);

  return (
    <Box>
      <Typography
        variant="body1"
        color="text.secondary"
        style={{ flex: 1, textAlign: "center" }}
      >
        <div className="spacer" />
        <b> {`${Math.round(props.value)}%`} </b>
        {props.value === 35 ? (
          <span>
            <b> Loading Summary Data ...</b>
          </span>
        ) : (
          ""
        )}
        {props.value === 50 ? (
          <span>
            <b> Loading NERs Data ...</b>
          </span>
        ) : (
          ""
        )}
        {props.value === 65 ? (
          <span>
            <b> Finishing Up ...</b>
          </span>
        ) : (
          ""
        )}
        {props.value === 100 ? (
          <span>
            <b>Completed</b>
            &nbsp; 
            <CHECK_MARK_ICON /> 
          </span>
        ) : (
          ""
        )}
      </Typography>
    </Box>
  );
}

export default function LinearBuffer(props) {
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(0);
  const [showDeleteCard, setShowDeleteCard] = useState(true);

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(100);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
    if (props.progressUpdate === 100) {
      setShowDeleteCard(true);
    }
  }, [props.progressUpdate]);

  return (
    <Box sx={{ width: "100%", margin: "0 auto" }}>
     

      <Paper
        className="progressPaperContainer"
        style={{ height: "150px", position: "relative" }}
        elevation={2}
      >
        <div className="spacer" />
        
     
        <Typography variant="body2" align="center" className="progressTypography">
           {
          props.progressUpdate === 35 ? <Typography >
          <b style={{ color: "#333" }}>
         Extracting file for Meta Data
          </b>
          </Typography>:
          ""
          }

          {
            props.progressUpdate === 50 ? <Typography >
            <b style={{ color: "#333" }}>
            Summary Data
            </b>
          </Typography>:
          ""
          }

          {
            props.progressUpdate === 65 ? <Typography >
            <b style={{ color: "#333" }}>
            NERs Data
            </b>
          </Typography>:
          ""
          }


          {
            props.progressUpdate === 100 ? props.fileName:
          ""
          }

        
        </Typography>

        <LinearProgress
          style={{ width: "90%", margin: "10px auto" }}
          variant="buffer"
          value={props.progressUpdate}
          valueBuffer={props.progressUpdate}
        />
        <LinearProgressWithLabel
          className="progressLinear"
          value={props.progressUpdate}
        />
   

      </Paper>
   
    </Box>
  )
}
