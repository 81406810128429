import React from "react";
import { Grid, Typography } from "@mui/material/";
import EmployeeRecognitionContent from "./content/employeeRecognition";
import { Helmet } from "react-helmet";

export default function EmployeeRecognitionPage() {
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      spacing={3}

      className="capabilitiesContainer"
    >
      <Helmet>
        <title>AWS Facial Recogntion</title>
      </Helmet>
      <Grid item xs={12} className="marginCenter">
        <div className="spacer" />
        <Typography
          style={{ color: "#006bb6", textAlign: "center" }}
          variant="h5"
          mb={5}
        >
          AWS Facial Recognition
        </Typography>
        <EmployeeRecognitionContent/>
      </Grid>
    </Grid>
  );
}
