import { Box, Tooltip, Typography } from "@mui/material";

const Mentions = ({ count }) => {
  const getBackgroundColor = () => {
    if (count >= 3 && count <= 5 ) {
      return "#2e7d32";
    } else if (count > 5) {
      return "#1976d2";
    } else {
      return "#a0a0a0";
    }
  };

  return (
    <Tooltip title={`${count} mentions`} placement="top">
      <Box
        position={"absolute"}
        top={-10}
        right={20}
        bottom={0}
        sx={{ backgroundColor: getBackgroundColor() }}
        padding={2}
        height={3}
        width={3}
        borderRadius={"100%"}
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Typography
          textAlign={"center"}
          color="#FFFFFF"
          fontSize={14}
          fontWeight={"bold"}
        >
          {count}
          <span style={{ fontSize: 10 }}>x</span>
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default Mentions;
