import React from "react";
import { Grid, Typography } from "@mui/material/";
import ComputerVision from "./content/computerVision";
import { Helmet } from "react-helmet";

export default function ComputerVisionPage() {
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      spacing={3}
      className="searchContainer"
    >
      <Helmet>
        <title>Instance Segmenetation</title>
      </Helmet>
      <Grid item xs={12} className="marginCenter">
        <ComputerVision />
      </Grid>
    </Grid>
  );
}
