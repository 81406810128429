import {
  Grid,
  Box,
  Typography,
  Paper,
  CircularProgress,
  Avatar,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import SaicBlueLogo from "../assets/images/Saic-Logo-Blue.png";
const EmployeeCard = ({ userDetails, showSimilarityPercentage = true }) => {
  return (
    <Paper sx={{ p: 3 }} elevation={2}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img
          src={SaicBlueLogo}
          style={{ objectFit: "contain", width: 70, marginBottom: 10 }}
        />
        {showSimilarityPercentage && (
          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CircularProgress
              variant="determinate"
              color="success"
              value={userDetails?.Similarity?.toFixed(2)}
              size={50}
              thickness={2.5}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="green"
                fontSize={10}
                fontWeight={"700"}
              >
                {`${
                  Number.isInteger(userDetails?.Similarity)
                    ? userDetails?.Similarity
                    : Math.floor(userDetails?.Similarity * 100) / 100
                }%`}
              </Typography>
              <Typography
                variant="caption"
                component="span"
                color="green"
                fontSize={8}
                fontWeight={"700"}
              >
                MATCH
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      <Box display={"flex"} alignItems="center" color="#006BB6">
        <Box mr={4}>
          <Typography fontWeight={600}>
            Employee Name: {userDetails?.firstName} {userDetails?.lastName}
          </Typography>
          <Typography fontWeight={600}>
            Department: {userDetails?.department}
          </Typography>
          <Typography fontWeight={600}>
            Job Title: {userDetails?.jobTitle}
          </Typography>
        </Box>
        <Box>
          {userDetails?.imageUrl && (
            <Avatar src={userDetails.imageUrl} sx={{ width: 56, height: 56 }} />
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default EmployeeCard;
