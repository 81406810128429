import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid, CardActions, Skeleton } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { createTheme } from "@mui/material/styles";
import ReactPaginate from "react-paginate";
import {
  RIGHT_ARROW_ICON,
  LEFT_ARROW_ICON,
  META_DATA_ICON,
} from "../assets/icons/IconList";

const inactiveTheme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#D0D0D0",
          color: "#939393",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#D0D0D0",
    },
    secondary: {
      main: "#11cb5f",
    },
  },
});

const rows = [];

function MetadataCard(props) {
  const title = ["Source File", "file NAme", "3", "4", "5"];

  let res = Object.keys(props.data).map(function (name) {
    var obj = {};
    obj[name] = props.data[name];
    return obj;
  });

  // const result =  Object.keys(props.data)

  const result = Object.keys(props.data).map((key) => props.data[key]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [pageCount, setPageCount] = useState(0);

  const [state, setState] = useState({
    dataValues: [],
  });

  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const endOffset = itemOffset + 4;

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + 4;
    setCurrentItems(Object.entries(props.data).slice(itemOffset, endOffset));
    setPageCount(Math.ceil(Object.entries(props.data).length / 4));
  }, [itemOffset, 4]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 4) % Object.entries(props.data).length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  function separateCamelCase(str) {
    return str.replace(/([a-z])([A-Z])/g, "$1 $2");
  }

  return (
    <Card sx={{ boxShadow: "1px 3.5px 0px rgba(177, 177, 177, 0.4)", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          align="left"
          style={{ color: "#1582d0" }}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <META_DATA_ICON />
          &nbsp; Metadata
        </Typography>

        <TableContainer style={{ marginTop: "15px", height: "315px" }}>
          <Table size="small">
            <TableHead>
              <TableRow style={{ textAlign: "center" }}>
                <TableCell style={{ color: "#fff", background: "#006bb6" }}>
                  Title
                </TableCell>
                <TableCell style={{ color: "#fff", background: "#006bb6" }}>
                  Name
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {currentItems &&
                currentItems.map((item) => (
                  <TableRow>
                    <TableCell>
                      <span>{separateCamelCase(item[0])}</span>
                    </TableCell>

                    <TableCell>
                      <span style={{ color: "#006bb6" }}>{item[1]}</span>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions>
        <ReactPaginate
          breakLabel="..."
          nextLabel={<RIGHT_ARROW_ICON />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}

          previousLabel={<LEFT_ARROW_ICON />}
          renderOnZeroPageCount={null}
          marginPagesDisplayed={2}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </CardActions>
    </Card>
  );
}

function InactiveMetadataCard(props) {
  return (
    <div>
      <Typography
        sx={{ color: "#006bb6" }}
        gutterBottom
        variant="h5"
        component="div"
        align="center"
      >
        Metadata
      </Typography>

      <Skeleton
        variant="rectangular"
        width={"100%"}
        height={325}
        style={{ borderRadius: "10px" }}
      >
        <Grid
          container
          xs={12}
          style={{ margin: "0 auto", textAlign: "center" }}
        >
          <Grid item xs={12} style={{ margin: "0 auto" }}>
            {props.spinner === true ? (
              <Grid item xs={12}>
                <div class="loadingContainer">
                  <div class="loading">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <div className="spacer" />
                </div>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Skeleton>
    </div>
  );
}

export { MetadataCard, InactiveMetadataCard };
