import React, { useMemo, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Tooltip,
  LinearProgress,
} from "@mui/material/";
import Select from "react-select";
import { toast } from "react-toastify";
import useWebSocket from "react-use-websocket";

import { PlayCircle, StopCircle } from "@mui/icons-material";

import { useFetchAllTfLiteTopics } from "../queries/edgeCv";

const TFLITE_CV_WS_BASE_URL = process.env.REACT_APP_TFLITE_CV_WS_BASE_URL;

export default function EdgeCvContent() {
  const [selectedTfLiteTopic, setSelectedTfLiteTopic] = useState(null);
  const [webSocketConnected, setWebSocketConnected] = useState(false);
  const [currentImageFrame, setCurrentImageFrame] = useState("");

  const {
    data: tfLiteTopics,
    isLoading: isLoadingBrokerTopics,
    error,
  } = useFetchAllTfLiteTopics();

  const tfLiteTopicOptions = useMemo(
    () =>
      tfLiteTopics?.map((type) => ({
        value: type.toLowerCase(),
        label: type.toUpperCase(),
      })),
    [tfLiteTopics]
  );

  useEffect(() => {
    /* on mount set first option as default */
    if (tfLiteTopicOptions) {
      setSelectedTfLiteTopic(tfLiteTopicOptions[0]);
    }
  }, [tfLiteTopicOptions]);

  useWebSocket(
    `${TFLITE_CV_WS_BASE_URL}/${selectedTfLiteTopic?.value}?read_from_start=true`,
    {
      onClose: () => {
        toast.success("WebSocket connection has closed");
      },
      onError: (err) => {
        console.log("On Error", err);
        toast.error("An error occurred with the WebSocket connection");
        setWebSocketConnected(false);
      },
      onOpen: () => {
        toast.success("WebSocket connection has started");
      },
      onMessage: (event) => {
        console.log(JSON.parse(event.data));

        const base64String = JSON.parse(event.data).image;

        const imageUrl = `data:image/jpeg;base64,${base64String}`; // Adjust the MIME type if needed

        setCurrentImageFrame(imageUrl);
      },
    },
    webSocketConnected
  );

  const toggleWebSocketConnection = () =>
    setWebSocketConnected(!webSocketConnected);

  return (
    <div
      className="capabilitiesContent"
      style={{ height: "100%", fontFamily: "Cormorant, serif" }}
    >
      <Grid container xs={12} spacing={3}>
        <Grid xs={6} item>
          <Typography
            variant="body1"
            component="div"
            fontFamily="Cormorant, serif"
            color={"#162227"}
            fontWeight="bold"
            fullWidth
            style={{ marginTop: "15px" }}
          >
            Select Image Topic
          </Typography>
          <Select
            isDisabled={isLoadingBrokerTopics || !!error || webSocketConnected}
            placeholder="Select topic..."
            onChange={setSelectedTfLiteTopic}
            value={selectedTfLiteTopic}
            options={tfLiteTopicOptions}
            styles={{
              control: (base, { isDisabled }) => ({
                ...base,
                backgroundColor: isDisabled ? "#6C757D" : undefined,
                // color: '#F8F1FF'
                // color: isFocused ? "#FFF" : undefined,
              }),
              option: (base, { isFocused }) => ({
                ...base,
                backgroundColor: isFocused ? "#0E284F" : undefined,
                color: isFocused ? "#FFF" : undefined,
              }),
            }}
          />

          {/* <div className="spacer" /> */}
          {/* 
          <Typography
            variant="body1"
            component="div"
            color={"#162227"}
            fontWeight="bold"
            fullWidth
            fontFamily="Cormorant, serif"
            style={{ marginTop: "15px" }}
          >
            Segmentation Interval{" "}
            <small style={{ color: "#999" }}>
              (<i>seconds</i>)
            </small>
          </Typography>

          <TextField
            type="number"
            style={{ width: "100px" }}
            name="segmentationInterval"
            className="inputBox"
            inputProps={{
              style: {
                height: "30px",
              },
            }}
            // {...register("segmentationInterval", {
            //   required: "Please provide an interval number",
            // })}
          /> */}

          <div className="spacer40" />

          <Grid container xs={12} display="flex" alignItems="center">
            <Grid item xs={3}>
              <Tooltip
                placement="top"
                title={
                  webSocketConnected ? "Stop WebSocket" : "Start WebSocket"
                }
              >
                <IconButton
                  disabled={isLoadingBrokerTopics || !!error}
                  onClick={toggleWebSocketConnection}
                >
                  {webSocketConnected ? (
                    <StopCircle
                      fontSize="large"
                      style={{
                        width: 70,
                        height: 70,
                        fill: "#DC3545",
                      }}
                    />
                  ) : (
                    <PlayCircle
                      //   color={
                      //     isLoadingBrokerTopics || !!error
                      //       ? "disabled"
                      //       : "success"
                      //   }
                      fontSize="large"
                      style={{
                        width: 70,
                        height: 70,
                        fill:
                          isLoadingBrokerTopics || !!error
                            ? "#6C757D"
                            : "#116466",
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              {webSocketConnected && (
                <LinearProgress
                  sx={{
                    backgroundColor: "#76BAFB",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#2A557F",
                    },
                  }}
                />
              )}
            </Grid>
          </Grid>

          {/* <Grid
            xs={12}
            style={{ marginTop: "25px", width: "90%", textAlign: "left" }}
          >
            <Box>
              <Typography variant="h6" style={{ color: "#006bb5" }}>
                Transcribed Text
              </Typography>
              <div
                id="results"
                style={{
                  width: "90%",
                  border: "2px solid #dee6eb",
                  borderRadius: "10px",
                  padding: "20px",
                  overflowY: "scroll",
                  height: "300px",
                  minHeight: "100px",
                }}
              >
                <div id="transcribedTextDiv"></div>
              </div>
            </Box>
          </Grid> */}
          <div className="spacer" />
          {/* <Grid
            xs={12}
            style={{ marginTop: "25px", width: "90%", textAlign: "left" }}
          >
            <Box>
              <Typography variant="h6" style={{ color: "#006bb5" }}>
                Summarized Text
              </Typography>

              <div
                id="summaryDiv"
                style={{
                  width: "90%",
                  border: "2px solid #dee6eb",
                  borderRadius: "10px",
                  padding: "20px",
                  overflowY: "scroll",
                  height: "300px",
                  minHeight: "100px",
                }}
              ></div>
            </Box>
          </Grid> */}
        </Grid>

        <Grid xs={6} item>
          <Typography
            variant="h6"
            color={"#162227"}
            fontFamily="Cormorant, serif"
            fullWidth
            style={{ marginTop: "15px" }}
          >
            Segmented Content
            <span
              style={{ color: "#999", paddingLeft: "25px", float: "right" }}
              id="timestamp"
            ></span>
          </Typography>

          {/* <div className="spacer" /> */}
          <Box
            style={{
              borderRadius: "10px",
              width: "100%",
              margin: "0 auto",
              height: "300px",
            }}
          >
            <div id="imageContainer">
              <img
                id="segmentedImage"
                width="100%"
                height="100%"
                src={currentImageFrame ? currentImageFrame : null}
                style={{
                  borderRadius: "15px",
                  minHeight: "200px",
                  border: "#0E284F 2px solid",
                }}
                className="gradient"
              />

              <div className="spacer" />
              {/* <div style={{ background: "#fff" }}>
                <div
                  id="segmentationData"
                  style={{
                    height: "350px",
                    borderRadius: "10px",
                    overflow: "scroll",
                    padding: "20px",
                    background: "#f3f3f3",
                    color: "#333",
                  }}
                ></div>
              </div> */}
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
