import { Card, CardContent, Typography, Grid, Skeleton } from "@mui/material/";
import { SUMMARY_ICON } from "../assets/icons/IconList";

function SummaryCard(props) {

  return (
    <Card
      sx={{
        maxWidth: 1050,
        boxShadow: "1px 3.5px 0px rgba(177, 177, 177, 0.4);",
      }}
    >
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          align="left"
          style={{ color: "#1582d0" }}
          sx={{
            display: 'flex', 
            alignItems: 'center'
          }}
        >
          <SUMMARY_ICON />
          &nbsp; Summary
        </Typography>

        <Typography
          align="justify"
          style={{
            padding: "15px",
            color: "#64696f",
            maxHeight: 350,
            overflow: "scroll",
          }}
        >
          {props.summaryData}
        </Typography>
      </CardContent>
    </Card>
  );
}

function InactiveSummaryCard(props) {
  return (
    <div>
      <Typography
        sx={{ color: "#006bb6" }}
        gutterBottom
        variant="h5"
        component="div"
        align="center"
      >
        Summary
      </Typography>

      <Skeleton
        variant="rectangular"
        width={'100%'}
        height={75}
        style={{ borderRadius: "10px" }}
      >
        <Grid
          container
          xs={12}
          style={{ margin: "0 auto", textAlign: "center" }}
        >
          <Grid item xs={12} style={{ margin: "0 auto" }}>
            {props.spinner === true ? (
              <Grid item xs={12}>
                <div class="loadingContainer">
                  <div class="loading">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <div className="spacer" />
                </div>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Skeleton>
    </div>
  );
}

export { SummaryCard, InactiveSummaryCard };
