import { useEffect, useRef } from "react";
import { Box, LinearProgress, Paper, Typography } from "@mui/material";

import Mentions from "./mentions";

const EntitiesList = ({ entities, webSocketConnected }) => {
  const paperRef = useRef(null);

  useEffect(() => {
    if (paperRef.current) {
      // @ts-ignore
      paperRef.current.scrollTop = paperRef.current.scrollHeight;
    }
  }, [entities]);

  return (
    <Box>
      <LinearProgress
        variant={webSocketConnected ? "indeterminate" : "determinate"}
        sx={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
        value={webSocketConnected ? undefined : 0}
      />

      <Paper
        elevation={2}
        ref={paperRef}
        sx={{
          height: 500,
          overflow: "scroll",
          backgroundColor: "#f8f7f7",
          position: "relative",
        }}
      >
        {entities?.map(({ value, type, mentions }) => (
          <Paper
            key={value}
            elevation={1}
            sx={{
              mt: 3,
              mb: 3,
              ml: 3,
              mr: 3,
              p: 2,
              width: "60%",
              position: "relative",
            }}
          >
            <Typography variant="body2" color={"textSecondary"}>
              {value} | {type}
            </Typography>
            {mentions > 1 && <Mentions count={mentions} />}
          </Paper>
        ))}
      </Paper>
    </Box>
  );
};

export default EntitiesList;
