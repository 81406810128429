import React from "react"
import { Grid, Typography } from "@mui/material/"
import Translator from "./content/translator"
import { Helmet } from "react-helmet"

export default function TranslatorPage() {
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      spacing={3}
      className="capabilitiesContainer"
    >
          <Helmet>
        <title>Translation Supporting 100 Languages</title>
      </Helmet>
      <Grid item xs={12} className="marginCenter">
        <div className="spacer" />
        <Typography style={{color: '#006bb6', textAlign: 'center'}} variant="h5" mb={2}>Translation Supporting 100 Languages</Typography>
        <Translator />
      </Grid>

    </Grid>
  )
}
