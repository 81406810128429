import React from "react";
import { Grid } from "@mui/material/";
import EdgeCvContent from "./content/edgeCv";

export default function EdgeCvPage() {
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      spacing={3}

      className="capabilitiesContainer"
    >
      <Grid item xs={12} className="marginCenter">
        <div className="spacer" />
        <EdgeCvContent/>
      </Grid>
    </Grid>
  );
}
