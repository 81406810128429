import { Button, Tooltip, Typography } from "@mui/material";
import { FileDownload } from "@mui/icons-material";
const ExportFileButton = ({ data, fileType, fileName, isDisabled }) => {
  const downloadFile = () => {
    const jsonData = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonData], { type: fileType });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleExport = (e) => {
    e.preventDefault();
    downloadFile();
  };

  return (
    <Tooltip
      title="Download JSON file containing all speech messages and entities thus far"
      placement="top"
    >
      <Button variant="contained" disabled={isDisabled} onClick={handleExport}>
        <Typography fontSize={12}>Export JSON</Typography>
        <FileDownload />
      </Button>
    </Tooltip>
  );
};

export default ExportFileButton;
