import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
  IconButton,
  Tooltip,
  LinearProgress,
} from "@mui/material/";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import useWebSocket from "react-use-websocket";
import { useDropzone } from "react-dropzone";
import { useForm, Controller } from "react-hook-form";

import { STOP_ICON } from "../assets/icons/IconList";
import { CloudUpload, PlayCircle } from "@mui/icons-material";
import { deleteVideo, uploadVideo } from "../service/capabilities";

const CAPABILITIES_WS_BASE_URL = process.env.REACT_APP_CAPABILITIES_WS_BASE_URL;

export default function CapabilitiesContent() {
  const [wsConnected, setWSConnected] = useState(false);
  const [videoId, setVideoId] = useState("");

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    disabled: wsConnected,
    accept: {
      "video/mp4": [".mp4"],
      "video/quicktime": [".mov"],
    },
  });

  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      file: null,
      segmentationInterval: 5,
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (vidId) => deleteVideo(vidId),
    onError: (e) => console.log("Error", e),
  });

  const mutation = useMutation({
    mutationFn: (formData) => {
      return uploadVideo(formData);
    },
    onSuccess: (successData) => {
      const videoId = successData?.video_uuid;
      setVideoId(videoId);
    },

    onError: (e) => console.log("Error", e),
  });

  useEffect(() => {
    if (videoId) setWSConnected(true);
  }, [videoId]);

  const startForm = (values) => {
    const file = values?.file[0];

    if (!file) {
      return toast.error("Please upload a file.");
    }
    const fileFormData = new FormData();
    fileFormData.append("file", file);
    mutation.mutate(fileFormData);
  };

  //?frames_per_second={frames_per_second}  1 "

  useWebSocket(
    `${CAPABILITIES_WS_BASE_URL}/inst-seg/${videoId}`,
    {
      onClose: () => {
        setWSConnected(false);
        toast.info("WebSocket segment has closed");
        if (!deleteMutation.isPending) deleteMutation.mutate(videoId);
      },
      onError: (err) => {
        toast.error("A WebSocket segment error has occurred");
        console.log("WebSocket Error:", err);
        if (!deleteMutation.isPending) deleteMutation.mutate(videoId);
      },
      onOpen: () => toast.info("WebSocket segment has opened"),
      onMessage: (event) => {
        const segmentedImage = document.getElementById("segmentedImage");
        const segmentationDataDiv = document.getElementById("segmentationData");

        if (event.data.includes("completed")) {
          return;
        }

        if (event.data) {
          const parsedEventData = JSON.parse(event.data);

          console.log("Parsed Event Data", parsedEventData);

          const imageString = `data:image/png;base64,${parsedEventData.annotated_image}`;

          document.getElementById(
            "timestamp"
          ).textContent = ` ${new Date().toLocaleString()}`;
          segmentedImage.src = imageString;

          let formattedData = `Timestamp: ${new Date().toISOString()}\n`;

          // Count categories
          const categoryCount = {};
          const annotations = parsedEventData.annotations || [];
          annotations.forEach((annotation) => {
            const categoryId = annotation.category_id;
            categoryCount[categoryId] = (categoryCount[categoryId] || 0) + 1;
          });

          // Display categories
          formattedData += "Categories:\n";
          for (const [categoryId, count] of Object.entries(categoryCount)) {
            const category = parsedEventData.categories.find(
              (cat) => cat.id === parseInt(categoryId)
            );
            if (category) {
              formattedData += `${category.name}: ${count}\n`;
            }
          }

          // Display annotations
          // formattedData += "Annotations:\n";
          // for (const [categoryId, count] of Object.entries(categoryCount)) {
          //   const category = parsedEventData.categories.find(
          //     (cat) => cat.id === parseInt(categoryId)
          //   );
          //   if (category) {
          //     for (let i = 1; i <= count; i++) {
          //       formattedData += `Annotation ${category.name} ${i}:\n`;
          //     }
          //   }
          // }

          // annotations.forEach((annotation, index) => {
          //   formattedData += `Area: ${annotation.area}%\n\n`;
          // });

          const preElement = document.createElement("pre");
          preElement.textContent = formattedData;
          segmentationDataDiv.appendChild(preElement);
          segmentationDataDiv.scrollTop = segmentationDataDiv.scrollHeight;
        }
      },
    },
    wsConnected
  );

  useWebSocket(
    `${CAPABILITIES_WS_BASE_URL}/stt/${videoId}`,
    {
      onOpen: () => toast.info("WebSocket audio has opened"),
      onClose: () => {
        toast.info("WebSocket audio has closed");
        if (!deleteMutation.isPending) deleteMutation.mutate(videoId);
      },
      onError: async (err) => {
        console.log(err);

        toast.error("WebSocket audio error occurred");
        if (!deleteMutation.isPending) deleteMutation.mutate(videoId);
      },
      onMessage: (event) => {
        if (event.data.includes("completed")) {
          return;
        }
        const parsedEventData = JSON.parse(event.data);

        const transcribedTextDiv =
          document.getElementById("transcribedTextDiv");
        transcribedTextDiv.innerHTML += parsedEventData.text.trim() + " ";
        // if (data.type === "transcription") {
        //   const transcribedTextDiv =
        //     document.getElementById("transcribedTextDiv");
        //   transcribedTextDiv.innerHTML += data.content + " ";
        // } else if (data.type === "summary") {
        //   const summaryDiv = document.getElementById("summaryDiv");
        //   summaryDiv.innerHTML += data.content + " ";
        // }
      },
    },
    wsConnected
  );

  const stopWebSocket = () => setWSConnected(false);

  const onSubmitError = (err) => {
    toast.error(Object.values(err)[0].message);
  };

  useEffect(() => {
    const setUploadedFilesVal = () => setValue("file", acceptedFiles);

    setUploadedFilesVal();
  }, [acceptedFiles, setValue]);

  return (
    <div
      className="capabilitiesContent"
      style={{ height: "100%", minHeight: "75vh" }}
    >
      <Grid container xs={12} spacing={3}>
        <Grid xs={6} item>
          <Controller
            control={control}
            name="file"
            render={() => (
              <Grid
                item
                {...getRootProps({ className: "dropzone" })}
                style={{
                  margin: "0 auto",
                  border: "dashed 2px #D0D0D0",
                  borderRadius: "5px",
                  padding: "25px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                className="css-selector"
              >
                <Grid item xs={12}>
                  <CloudUpload
                    style={{ width: 100, height: 100, color: "#006bb6" }}
                  />

                  <div>
                    <input {...getInputProps()} />
                    <Typography
                      sx={{ textDecoration: "underline" }}
                      style={{ color: "#006bb6" }}
                      variant="h6"
                    >
                      Click to upload a video file
                    </Typography>
                    <Typography variant="h6">or drag and drop</Typography>

                    <Typography
                      sx={{ fontStyle: "italic" }}
                      variant="subtitle2"
                      style={{ color: "#999" }}
                    >
                      (Only files with .mov or .mp4 extensions)
                    </Typography>

                    {acceptedFiles?.map((file) => (
                      <Typography color="#006BB6" key={file.name}>
                        {file.path} - {file.size} bytes
                      </Typography>
                    ))}
                  </div>
                </Grid>
              </Grid>
            )}
          />

          <div className="spacer" />

          <Typography
            variant="body1"
            component="div"
            color={"#006bb5"}
            fontWeight="bold"
            fullWidth
            style={{ marginTop: "15px" }}
          >
            Segmentation Interval{" "}
            <small style={{ color: "#999" }}>
              (<i>seconds</i>)
            </small>
          </Typography>

          <TextField
            type="number"
            disabled
            style={{ width: "100px" }}
            name="segmentationInterval"
            className="inputBox"
            inputProps={{
              style: {
                height: "30px",
              },
            }}
            {...register("segmentationInterval", {
              required: "Please provide an interval number",
            })}
          />

          <Grid container xs={12} display="flex" alignItems="center" mt={2}>
            <Grid item xs={3}>
              <Tooltip
                placement="top"
                title={wsConnected ? "Stop WebSocket" : "Start WebSocket"}
              >
                <IconButton
                  disabled={mutation.isPending}
                  onClick={
                    wsConnected
                      ? () => stopWebSocket()
                      : handleSubmit(startForm, onSubmitError)
                  }
                >
                  {wsConnected ? (
                    <STOP_ICON />
                  ) : (
                    <PlayCircle
                      fontSize="large"
                      color={mutation.isPending ? "disabled" : "success"}
                      style={{ fontSize: 60 }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              {wsConnected && <LinearProgress color="info" />}
            </Grid>
          </Grid>

          <Grid
            xs={12}
            style={{ marginTop: "25px", width: "90%", textAlign: "left" }}
          >
            <Box>
              <Typography variant="h6" style={{ color: "#006bb5" }}>
                Transcribed Text
              </Typography>
              <div
                id="results"
                style={{
                  width: "90%",
                  border: "2px solid #dee6eb",
                  borderRadius: "10px",
                  padding: "20px",
                  overflowY: "scroll",
                  height: "300px",
                  minHeight: "100px",
                }}
              >
                <div id="transcribedTextDiv"></div>
              </div>
            </Box>
          </Grid>
          <div className="spacer" />
          {/* <Grid
            xs={12}
            style={{ marginTop: "25px", width: "90%", textAlign: "left" }}
          >
            <Box>
              <Typography variant="h6" style={{ color: "#006bb5" }}>
                Summarized Text
              </Typography>

              <div
                id="summaryDiv"
                style={{
                  width: "90%",
                  border: "2px solid #dee6eb",
                  borderRadius: "10px",
                  padding: "20px",
                  overflowY: "scroll",
                  height: "300px",
                  minHeight: "100px",
                }}
              ></div>
            </Box>
          </Grid> */}
        </Grid>

        <Grid xs={6} item>
          <Typography
            variant="h6"
            color={"#006bb5"}
            fullWidth
            style={{ marginTop: "15px" }}
          >
            Segmented Content
            <span
              style={{ color: "#999", paddingLeft: "25px", float: "right" }}
              id="timestamp"
            ></span>
          </Typography>

          <div className="spacer" />
          <Box
            style={{
              borderRadius: "10px",
              width: "100%",
              margin: "0 auto",
              height: "300px",
            }}
          >
            <div id="imageContainer">
              <img
                id="segmentedImage"
                width="100%"
                height="100%"
                style={{
                  borderRadius: "15px",
                  minHeight: "200px",
                  border: "#0b80d4 1px solid",
                }}
                className="gradient"
              />

              <div className="spacer" />
              <div style={{ background: "#fff" }}>
                <div
                  id="segmentationData"
                  style={{
                    height: "350px",
                    borderRadius: "10px",
                    overflow: "scroll",
                    padding: "20px",
                    background: "#f3f3f3",
                    color: "#333",
                  }}
                ></div>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
