import React, { useState } from "react";
import { Grid, Typography } from "@mui/material/";
import Stack from "@mui/material/Stack";

import DragAndDrop from "../components/dragndrop";
import { SummaryCard, InactiveSummaryCard } from "../components/summary";
import { NERCard, InactiveNERCard } from "../components/NER";
import { MetadataCard, InactiveMetadataCard } from "../components/metadata";
import { MyProvider } from "../myContext";
import {
  EntitiesHTMLCard,
  InactiveEntitiesHTMLCard,
} from "../components/entitiesHtmlCard";

export default function Upload() {
  const [switchView, switchLayoutsetSwitchView] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [switchActiveStateCardColor, setSwitchActiveStateCardColor] =
    React.useState(false);
  const [initSpinner, setInitSpinner] = React.useState(false);
  const [metaData, setMetaData] = React.useState();
  const [nersData, setNersData] = React.useState([]);
  const [summaryData, setSummaryData] = React.useState();

  const [entitiesHTML, setEntitiesHTML] = useState(null);

  const switchLayout = (view) => {
    switchLayoutsetSwitchView(view);
  };

  const acitvateInitSpinner = (spin) => {
    setInitSpinner(spin);
  };

  const transitionLayout = (view) => {
    setShowLoader(view);
  };

  const getMetaData = (metaData) => {
    setSwitchActiveStateCardColor(true);
    setMetaData(metaData);
  };

  const getSummaryData = (sumData) => {
    setSummaryData(sumData);
  };

  const getNersData = (nersData) => {
    setNersData(nersData);
    setSwitchActiveStateCardColor(false);
  };

  const getEntitiesHtml = (html) => setEntitiesHTML(html);

  return (
    <Grid container xs={12}>
      <Grid xs={12} item>
      <Typography style={{color: '#006bb6', textAlign: 'center'}} variant="h5" mb={2}>NLP Capabilities</Typography>
        {/* <Typography
          sx={{
            color: "#9a9a9a",
          }}
        >
          <i>Powered by:</i>
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "600",
            color: "#64696f",
          }}
        >
          Mission Accelerators
        </Typography> */}
      </Grid>

      <Grid xs={5} marginTop={2}>
        <Stack direction="column">
          <DragAndDrop
            view={switchLayout}
            transView={transitionLayout}
            meta={getMetaData}
            summary={getSummaryData}
            nersData={getNersData}
            spinner={acitvateInitSpinner}
            entitiesHTML={getEntitiesHtml}
          />
        </Stack>
      </Grid>

      {switchView === false ? (
        <Grid container xs={7}>
          <Grid xs={12} padding={3}>
            <InactiveSummaryCard />
          </Grid>

          <Grid xs={6} padding={3}>
            <InactiveNERCard />
          </Grid>

          <Grid xs={6} padding={3}>
            {initSpinner === true ? (
              <InactiveMetadataCard spinner={initSpinner} />
            ) : (
              <InactiveMetadataCard />
            )}
          </Grid>
          <Grid xs={12} padding={3}>
            <InactiveEntitiesHTMLCard />
          </Grid>
        </Grid>
      ) : (
        <Grid container xs={7}>
          <MyProvider value={{ summary: summaryData }}>
            <Grid xs={12} padding={3}>
              {switchActiveStateCardColor === true ? (
                <span>
                  <InactiveSummaryCard spinner={true} />
                </span>
              ) : (
                <SummaryCard summaryData={summaryData} />
              )}
            </Grid>

            <Grid xs={6} padding={3} style={{ marginTop: "5px" }}>
              {switchActiveStateCardColor === true ? (
                <InactiveNERCard spinner={true} />
              ) : (
                <NERCard nersData={nersData} />
              )}
            </Grid>

            <Grid xs={6} padding={3} style={{ marginTop: "5px" }}>
              <MetadataCard data={metaData} />
            </Grid>
            <Grid xs={12} padding={3}>
              {switchActiveStateCardColor === true ? (
                <span>
                  <InactiveEntitiesHTMLCard spinner={true} />
                </span>
              ) : (
                <EntitiesHTMLCard entitiesHTML={entitiesHTML} />
              )}
            </Grid>
          </MyProvider>
        </Grid>
      )}
    </Grid>
  );
}
