import React from "react";
import { Grid, Typography, Box, Tab, Tabs } from "@mui/material/";

import VerifyEmployeePanel from "../components/verifyEmployeePanel";
import AddEmployeePanel from "../components/addEmployeePanel";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EmployeeRecognitionContent() {
  const [currentTabValue, setCurrentTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTabValue(newValue);
  };

  return (
    <div
      className="capabilitiesContent"
      style={{ height: "100%", display: "flex", justifyContent: "center" }}
    >
      <Grid container xs={6}>
        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
             
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Tabs
                value={currentTabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                <Tab label="Verify Employee" {...a11yProps(0)} />
                <Tab label="Register Employee" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={currentTabValue} index={0}>
              <VerifyEmployeePanel />
            </CustomTabPanel>
            <CustomTabPanel value={currentTabValue} index={1}>
              <AddEmployeePanel />
            </CustomTabPanel>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
