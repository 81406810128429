import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { Link, animateScroll as scroll } from "react-scroll";
import { toast } from "react-toastify";
import { Grid, Button, Typography, IconButton } from "@mui/material/";
import { UPLOAD_ICON, PHOTO_ICON } from "../assets/icons/IconList";
import DeleteIcon from "@mui/icons-material/Delete";
import ProgressBar from "./progress";

const SEMANTIC_IMAGE_UPLOAD_URL = process.env.REACT_APP_SEMANTIC_IMAGE_UPLOAD_URL
const INSTANCE_IMAGE_UPLOAD_URL = process.env.REACT_APP_INSTANCE_IMAGE_UPLOAD_URL

export default function DragAndDrop(props) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [updateProgress, setUpdateProgress] = useState();
  const [hideButton, setHideButton] = useState(false);
  const [open, setOpen] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [files, setFiles] = useState([]);
  const [initSpinner, setInitSpinner] = useState(false);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      multiple: false,
      disabled: initSpinner,
      accept: {
        "image/jpeg": [],
        "image/png": [],
      },
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

  const thumbOrginalFile = files.map((file) => (
    <div key={file.name}>
      <div style={{ borderRadius: "10px" }}>
        <img
          src={file.preview}
          style={{
            borderRadius: "10px",
            marginTop: "0",
            border: "2px #d3d3d3 solid",
          }}
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />

        <img
          src={file.preview}
          style={{ opacity: "0" }}
          onLoad={() => {
            handleUploadFiles();
          }}
        />
      </div>
    </div>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const handleDelete = () => {
    setOpen(true);
  };

  const handleUploadFiles = async () => {
    var image = document.getElementById("myImage");
    if (image.src) image.src = "";

    let formData = new FormData();
    formData.append("file", acceptedFiles[0]);

    scroll.scrollTo(1000);
    setInitSpinner(true);

    let endpointType;
    if (props.selectedSegmentation === "semantic") {
      endpointType = SEMANTIC_IMAGE_UPLOAD_URL;
    }

    if (props.selectedSegmentation === "instance") {
      endpointType = INSTANCE_IMAGE_UPLOAD_URL;
    }

    try {
      const response = await axios({
        method: "POST",
        url: endpointType,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      });

      console.log(response.data);

      var blobURL = URL.createObjectURL(response.data);

      image.onload = function () {
        URL.revokeObjectURL(this.src);
      };

      image.src = blobURL;

      toast.success("Image processed successfully");
    } catch (error) {
      toast.error("An error occurred, please try again...");
      console.error(error);
    } finally {
      setInitSpinner(false);
      setHideButton(true);
    }
    setFileUploaded(true);
  };

  const acceptedFileItems = acceptedFiles.map((file) => (
    <span key={file.path} style={{ color: "#006BB6" }}>
      &nbsp; {file.path} - {file.size} bytes
    </span>
  ));

  return (
    <Grid xs={10} container style={{ height: "75vh", margin: "0 auto" }}>
      {!showLoader ? (
        ""
      ) : (
        <div class="animation01">
          <div class="rhombus_small">
            <div class="rhombus">
              <div class="border_box">
                <span class="line line01"></span>
                <span class="line line02"></span>
                <span class="line line03"></span>
                <span class="line line04"></span>
              </div>
            </div>
          </div>
        </div>
      )}

      <Grid
        xs={12}
        item
        {...getRootProps({ className: "dropzone" })}
        style={{
          margin: "-65px auto",
          border: "dashed 2px #D0D0D0",
          borderRadius: "5px",
          padding: "25px",
          textAlign: "center",
          cursor: "pointer",
          height: "350px",
        }}
        className="css-selector"
      >
        <div className="spacer" />
        <Grid item xs={12} style={{ margin: "-25px auto" }}>
          <PHOTO_ICON />
          <div className="spacer" />
          <div>
            <input {...getInputProps()} />

            <Typography
              sx={{ textDecoration: "underline" }}
              style={{ color: "#006bb6" }}
              variant="h6"
            >
              Click to upload Image
            </Typography>
            <Typography variant="h6">or drag and drop</Typography>
            <div className="spacer" />

            {/* <Typography
              sx={{ fontStyle: "italic" }}
              variant="subtitle2"
              style={{ color: "#999" }}
            >
              (Only files with name less than 20 characters will be accepted)
            </Typography> */}
          </div>
          <Typography style={{ color: "#00acd4" }}>
            {acceptedFileItems}

            {acceptedFileItems.length > 0 && fileUploaded && (
              <IconButton onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            )}
          </Typography>
        </Grid>
      </Grid>

      <Grid container xs={12} className="imagePreview fadePanel">
        <Grid item xs={12}>
          <div className="spacer" />
          {/* {acceptedFileItems.length != 1 ? (
                    ""
                  ) : (
     
                    <span>
            
                     
                      {hideButton === false ? (
                        
                        <Button
                          variant="contained"
                          onClick={handleUploadFiles}
                          style={{
                            margin: "-25px auto",
                            display: "flex",
                            backgroundColor: "#006bb6",
                            width: '200px',
                            padding: '15px',
                            textAlign: 'center'
                          }}
                          className="fadePanel"
                        
                        >
                          UPLOAD
                        </Button>
                      ) : (
                        ""
                      )} 
                    </span>
                  )} */}
        </Grid>
        <div className="spacer40" />
        <Grid item xs={5}>
          <Typography
            variant="h6"
            style={{ textAlign: "left", color: "#64696f" }}
          >
            Uploaded Image
          </Typography>
        </Grid>

        <Grid item xs={2} />

        <Grid item xs={5}>
          <Typography
            variant="h6"
            style={{ textAlign: "left", color: "#64696f" }}
          >
            Segmented Image
          </Typography>
        </Grid>

        <Grid
          item
          xs={5}
          style={{
            borderRadius: "10px",
            border: "2px solid #d3d3d3",
            height: "200px",
          }}
        >
          {thumbOrginalFile}
        </Grid>

        <Grid item xs={2} />

        <Grid
          item
          xs={5}
          style={{
            borderRadius: "10px",
            border: "2px solid #d3d3d3",
            height: "200px",
          }}
        >
          {initSpinner ? (
            <Grid item xs={3} style={{ margin: "50px auto" }}>
              <div class="lds-dual-ring"></div>
            </Grid>
          ) : (
            ""
          )}

          <img id="myImage" src="" className="fadePanel" />
        </Grid>
      </Grid>

      {!showSpinner ? (
        ""
      ) : (
        <Grid item xs={12}>
          <div className="spacer" />
          <Typography
            variant="h6"
            color="text.secondary"
            style={{ flex: 1, textAlign: "center" }}
          >
            <b> Initializing ...</b>
          </Typography>
        </Grid>
      )}

      {updateProgress <= 100 ? (
        <ProgressBar
          progressUpdate={updateProgress}
          fileName={acceptedFileItems}
        />
      ) : (
        ""
      )}

      <div className="spacer" />
      <div className="spacer" />
    </Grid>
  );
}
