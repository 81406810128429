import { useState, useMemo } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material/";
import { useDropzone, FileWithPath } from "react-dropzone";
import {
  Portrait,
  CloudUpload,
  CheckCircle,
  Error,
  Replay,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

import { postVerifyEmployee } from "../service/recognition";
import EmployeeCard from "./employeeCard";

const FILE_INPUT_NAME = "verifyFile";

const VerifyEmployeePanel = () => {
  const [authUserDetails, setAuthUserDetails] = useState(null);
  const [error, setError] = useState("");

  const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({
    multiple: false,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },

    onDrop: (acceptedFiles) => {
      setValue(FILE_INPUT_NAME, acceptedFiles[0]);
      setAuthUserDetails(null);
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      [FILE_INPUT_NAME]: null,
    },
  });
  const imgPreviewUrl = useMemo(() => {
    if (getValues(FILE_INPUT_NAME))
      return URL.createObjectURL(getValues(FILE_INPUT_NAME));
    else return null;
  }, [getValues(FILE_INPUT_NAME)]);

  const verifyEmployee = async (data) => {
    setError("");
    setAuthUserDetails(null);
    try {
      const fileFormData = new FormData();
      fileFormData.append("file", data[FILE_INPUT_NAME]);
      const response = await postVerifyEmployee(fileFormData);
      if (response.Status === "Successful Match") {
        toast.success(`Welcome ${response.firstName}, you've been verified`);
        setAuthUserDetails(response);
      } else {
        toast.error(response.Message);
        setError(response.Message);
      }
    } catch (e) {
      console.log("Error occurred attempting to verify employee", e);
      toast.error("An error occurred, please try again");
      setError("An unknown error occurred");
      throw e;
    }
  };

  const handleReset = () => {
    setAuthUserDetails(null);
    setError("");
    reset();
  };

  return (
    <>
      {authUserDetails && (
        <Grid item display="flex" justifyContent="center" mb={1.5}>
          <IconButton
            sx={{ boxShadow: "1px 1px 2px 1px gray" }}
            onClick={handleReset}
          >
            <Replay style={{ width: 24, height: 24 }} />
          </IconButton>
        </Grid>
      )}
      <Grid container display={"flex"} justifyContent={"center"}>
        {authUserDetails ? (
          <Grid
            item
            alignItems="center"
            flexDirection="column"
            display="flex"
            mb={1.5}
          >
            <Box display={"flex"} alignItems="center" mb={1.5}>
              <Typography
                color="green"
                fontWeight={700}
                textTransform={"uppercase"}
              >
                {authUserDetails.firstName} {authUserDetails.lastName} Verified
              </Typography>
              <CheckCircle
                style={{
                  width: 24,
                  height: 24,
                  color: "#008001",
                  marginLeft: 8,
                }}
              />
            </Box>
            <EmployeeCard userDetails={{ ...authUserDetails }} />
          </Grid>
        ) : (
          <>
            {isSubmitting ? (
              <Grid
                container
                item
                display={"flex"}
                alignItems="center"
                justifyContent={"center"}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <>
                {error && (
                  <Grid
                    item
                    alignItems="center"
                    flexDirection="column"
                    display="flex"
                    mt={1.5}
                  >
                    <Box display={"flex"} alignItems="center" mb={1.5}>
                      <Typography
                        color="red"
                        fontWeight={700}
                        textTransform={"uppercase"}
                      >
                        Error occurred: {error}
                      </Typography>
                      <Error
                        style={{
                          width: 24,
                          height: 24,
                          color: "red",
                          marginLeft: 8,
                        }}
                      />
                    </Box>
                  </Grid>
                )}
                <Grid
                  container
                  display="flex"
                  alignItems={"center"}
                  justifyContent={'center'}
                  columnGap={3.5}
                >
                  <Grid
                    item
                    {...getRootProps({ className: "dropzone" })}
                    style={{
                      border: "dashed 2px #D0D0D0",
                      borderRadius: 4,
                      padding: 20,
                      textAlign: "center",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                      maxWidth: "50%",
                    }}
                  >
                    <CloudUpload
                      style={{ width: 80, height: 80, color: "#00acd4" }}
                    />
                    <input
                      {...getInputProps()}
                      {...register(FILE_INPUT_NAME)}
                    />
                    <Typography variant="h5" color="#64696f">
                      Click to upload image
                    </Typography>
                    <Typography variant="h5" color="#64696f">
                      or drag and drop
                    </Typography>
                    {acceptedFiles?.map((file) => (
                      <Typography color="#006BB6" key={file.name}>
                        {file?.path} - {file.size} bytes
                      </Typography>
                    ))}
                  </Grid>
                  <Grid item xs={4} height={300}>
                    {imgPreviewUrl ? (
                      <img
                        src={imgPreviewUrl}
                        style={{
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: 4,
                          boxShadow: "1px 1px 1px 1px gray",
                        }}
                      />
                    ) : (
                      <Box
                        style={{
                          width: 300,
                          height: "100%",

                          borderRadius: 4,
                          boxShadow: "1px 1px 1px 1px gray",
                        }}
                      >
                        <Portrait
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "#00acd4",
                          }}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Button
                    variant="contained"
                    disabled={!getValues(FILE_INPUT_NAME) || isSubmitting}
                    onClick={handleSubmit(verifyEmployee)}
                  >
                    Authenticate
                  </Button>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default VerifyEmployeePanel;
