import React from "react";
import { Grid, Typography } from "@mui/material/";
import Capabilities from "./content/capabilities";
import { Helmet } from "react-helmet";

export default function CapabilitiesPage() {
  return (
    <Grid container xs={12} spacing={3} className="capabilitiesContainer">
          <Helmet>
        <title>Video Inference</title>
      </Helmet>
      <Grid item xs={12} className="marginCenter">
        <div className="spacer" />
        <Typography
          style={{ color: "#006bb6", textAlign: "center" }}
          variant="h5"
          mb={5}
        >
          Video Inference
        </Typography>
        <Capabilities />
      </Grid>
    </Grid>
  );
}
