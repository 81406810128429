import * as React from "react";
import IconButton from "@mui/material/IconButton";
import { Menu, MenuItem } from "@mui/material/";
import { MENU_ICON, TRIANGLE_ICON } from "../assets/icons/IconList";

const ITEM_HEIGHT = 60;

export default function PrimaryMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const url = window.location.href;

  return (
    <div className="menuContainer">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MENU_ICON />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 5.5,
            width: "250px",
            color: "#4299f7",
            paddingBottom: "10px",
            borderBottom: "solid 2px #006bb6",
          },
        }}
      >
        {/* {url.includes("search") === true ? (
          <MenuItem className="floating">
            <a
              style={{
                background: "#006bb6",
                color: "#fff",
                width: "100%",
                borderRadius: "5px",
                padding: "5px",
              }}
              href="/search"
            >
              SEARCH
            </a>
          </MenuItem>
        ) : (
          <MenuItem>
            <a style={{ color: "#006bb6", padding: "5px" }} href="/search">
              SEARCH
            </a>
          </MenuItem>
        )} */}

        {url.includes("upload") === true ? (
          <MenuItem className="floating">
            <a
              style={{
                background: "#006bb6",
                color: "#fff",
                width: "100%",
                borderRadius: "5px",
                padding: "5px",
              }}
              href="/upload"
            >
              NLP CAPABILITIES
            </a>
          </MenuItem>
        ) : (
          <MenuItem>
            <a style={{ color: "#006bb6", padding: "5px" }} href="/upload">
              NLP CAPABILITIES
            </a>
          </MenuItem>
        )}

        {url.includes("computerVision") === true ? (
          <MenuItem className="floating">
            <a
              style={{
                background: "#006bb6",
                color: "#fff",
                width: "100%",
                borderRadius: "5px",
                padding: "5px",
              }}
              href="/computerVision"
            >
              COMPUTER VISION
            </a>
          </MenuItem>
        ) : (
          <MenuItem>
            <a
              style={{ color: "#006bb6", padding: "5px" }}
              href="/computerVision"
            >
              COMPUTER VISION
            </a>
          </MenuItem>
        )}

        {url.includes("capabilities") === true ? (
          <MenuItem className="floating">
            <a
              style={{
                background: "#006bb6",
                color: "#fff",
                width: "100%",
                borderRadius: "5px",
                padding: "5px",
              }}
              href="/capabilities"
            >
              CAPABILITIES
            </a>
          </MenuItem>
        ) : (
          <MenuItem>
            <a
              style={{ color: "#006bb6", padding: "5px" }}
              href="/capabilities"
            >
              CAPABILITIES
            </a>
          </MenuItem>
        )}

        {url.includes("translator") === true ? (
          <MenuItem className="floating">
            <a
              style={{
                background: "#006bb6",
                color: "#fff",
                width: "100%",
                borderRadius: "5px",
                padding: "5px",
              }}
              href="/translator"
            >
              TRANSLATOR
            </a>
          </MenuItem>
        ) : (
          <MenuItem>
            <a style={{ color: "#006bb6", padding: "5px" }} href="/translator">
              TRANSLATOR
            </a>
          </MenuItem>
        )}
        {url.includes("speech-to-text") === true ? (
          <MenuItem className="floating">
            <a
              style={{
                background: "#006bb6",
                color: "#fff",
                width: "100%",
                borderRadius: "5px",
                padding: "5px",
              }}
              href="/speech-to-text"
            >
                AUDIO BIOMETRIC EXPLOITATION
            </a>
          </MenuItem>
        ) : (
          <MenuItem>
            <a
              style={{ color: "#006bb6", padding: "5px" }}
              href="/speech-to-text"
            >
              AUDIO BIOMETRIC EXPLOITATION
            </a>
          </MenuItem>
        )}

        {url.includes("employee-recognition") === true ? (
          <MenuItem className="floating">
            <a
              style={{
                background: "#006bb6",
                color: "#fff",
                width: "100%",
                borderRadius: "5px",
                padding: "5px",
              }}
              href="/employee-recognition"
            >
              AWS FACIAL RECOGNITION
            </a>
          </MenuItem>
        ) : (
          <MenuItem>
            <a
              style={{ color: "#006bb6", padding: "5px" }}
              href="/employee-recognition"
            >
              AWS FACIAL RECOGNITION
            </a>
          </MenuItem>
        )}
        {/* {url.includes("facial-recognition") === true ? (
          <MenuItem className="floating">
            <a
              style={{
                background: "#006bb6",
                color: "#fff",
                width: "100%",
                borderRadius: "5px",
                padding: "5px",
              }}
              href="/employee-recognition"
            >
              HVT Identification
            </a>
          </MenuItem>
        ) : (
          <MenuItem>
            <a
              style={{ color: "#006bb6", padding: "5px" }}
              href="/employee-recognition"
            >
              HVT Identification
            </a>
          </MenuItem>
        )} */}
      </Menu>
    </div>
  );
}
