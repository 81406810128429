import { useMemo } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";

const FacialRecognitionIdentityDisplay = ({ identityResults = [] }) => {
  const theme = useTheme();
  const isSmallViewport = useMediaQuery(theme.breakpoints.down("sm"))

  const matchedResults = useMemo(
    () => identityResults.filter((result) => result?.match),
    [identityResults]
  );

  const noMatchResults = useMemo(
    () => identityResults.filter((result) => !result?.match),
    [identityResults]
  );

  return (
    <Box
      style={{
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: "#ccc",
        width: "100%",
        height: isSmallViewport ? 200 : "100%",
        borderRadius: 4,
        backgroundColor: "#f8f7f7",
        overflow: "scroll",
      }}
    >
      <div style={{ padding: 20 }}>
        {matchedResults?.length > 0 && (
          <div
            style={{
              color: "#64696f",
              marginBottom: 10,
            }}
          >
            <div
              style={{
                borderBottom: "1px solid #64696f",
                textTransform: "uppercase",
                fontWeight: "700",
                textAlign: "center",
                color: "#64696f",
              }}
            >
              <div>Matches Found</div>
            </div>
            {matchedResults.map(({ identity_attributes }) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 5,
                  marginBottom: 5,
                  color:
                    identity_attributes.threat_level === "high"
                      ? "#d32f2f"
                      : "inherit",
                }}
              >
                {identity_attributes.threat_level === "high" && (
                  <NewReleasesOutlinedIcon
                    style={{ marginRight: 5, color: "#d32f2f" }}
                    fontSize="small"
                  />
                )}
                <span style={{ fontWeight: "700" }}>
                  {identity_attributes.Name}
                </span>{" "}
                - <span>{identity_attributes.agency}</span> -{" "}
                <span>{identity_attributes.last_location}</span>
              </div>
            ))}
          </div>
        )}
        {noMatchResults?.length > 0 && (
          <div
            style={{
              color: "#64696f",
              marginBottom: 10,
            }}
          >
            <div
              style={{
                borderBottom: "1px solid #64696f",
                textTransform: "uppercase",
                fontWeight: "700",
                textAlign: "center",
                color: "#64696f",
              }}
            >
              <div>Matches Not Found</div>
            </div>
            {noMatchResults.map((result) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                <span style={{ fontWeight: "700" }}>Figure {result.index}</span>{" "}
              </div>
            ))}
          </div>
        )}
      </div>
    </Box>
  );
};

export default FacialRecognitionIdentityDisplay;