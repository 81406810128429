import { useQuery } from "@tanstack/react-query";

import {
  getAllTopics,
  getEntitiesSummary,
  getTextSummary,
} from "../service/speechToText";

export const redPandaTopicsQueryKeys = {
  all: ["allTopics"],
};

export const useFetchAllRedPandaTopicsQuery = (options) =>
  useQuery({
    queryKey: redPandaTopicsQueryKeys.all,
    queryFn: getAllTopics,
    ...options,
  });

export const entitiesSummaryQueryKeys = {
  single: (summaryText) => ["entitiesSummary", summaryText],
};

export const useFetchEntitiesSummaryQuery = (summaryText, options) =>
  useQuery({
    queryKey: entitiesSummaryQueryKeys.single(summaryText),
    queryFn: () => getEntitiesSummary(summaryText),
    ...options,
  });

export const textSummaryQueryKeys = {
  single: (text) => ["textSummary", text],
};

export const useFetchTextSummaryQuery = (text, options) =>
  useQuery({
    queryKey: textSummaryQueryKeys.single(text),
    queryFn: () => getTextSummary(text),
    ...options,
  });
