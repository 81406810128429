import axios from "axios";

const RP_URL = process.env.REACT_APP_RP_BASE_URL;

const ENTITIES_URL = process.env.REACT_APP_ENTITIES_URL;

const SPEECH_SUMMARIZATION_URL = process.env.REACT_APP_SPEECH_SUMMARIZATION_URL;

export const getAllTopics = async () => {
  try {
    const response = await axios.get(`${RP_URL}/broker/stt/topics`);
    return response.data;
  } catch (error) {
    console.error("Error fetching all topics", error);
    throw error;
  }
};

export const getEntitiesSummary = async (summary) => {
  try {
    const response = await axios.post(ENTITIES_URL, {
      prompt: summary,
    });

    return response.data;
  } catch (error) {
    console.error("Error making request for entities summary response", error);
    throw error;
  }
};

export const getTextSummary = async (text) => {
  try {
    const response = await axios.post(SPEECH_SUMMARIZATION_URL, {
      input_text:text,
    });

    return response.data;
  } catch (error) {
    console.error("Error making request for text summary response", error);
    throw error;
  }
};
